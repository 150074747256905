import { PartStatus } from "~shared/enums";

import { Project } from "./project.types";

export interface Part {
	id: string;
	name: string;
	status_seq: number;
	locked: boolean;
	valid: boolean;
	validFrom: string;
	validUntil: string;
	creationDate: string;
	status: PartStatus;
	space?: {
		id: string;
		name: string;
	};
	project: Project;
	projectId: string;
	expectedDeliveryDate: string;
}
