import { validateFields } from "~shared/helpers";

import { ConnectionType, HandleLocation, HingeType } from "../enums/item.enum";

import { ICoordinates } from "./dimension.types";
import { HardwareInterface } from "./hardware.types";


export class Connection{
	connectionType?: ConnectionType;
	quantity?: number;
	location?: HandleLocation;
	locations?: HandleLocation[];
	hardware?: HardwareInterface[];
	hingeType?: HingeType;
	pin?: boolean;
	offset?: ICoordinates;

	constructor(data: Partial<Connection>) {
		validateFields(data, [{key: "hardware", instanceType: HardwareInterface, isArray: true}])
		Object.assign(this, data);
	}

}
