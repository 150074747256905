import { IConstraint } from "./constraint.types";

export interface DimensionField {
	label: string;
}

export interface Dimension {
	value: number;
	constraints?: IConstraint[];
	modifiable?: boolean;
	measurementUnit?: string;
}

export interface TopDimensions {
	T1?: Dimension;
	T2?: Dimension;
	T3?: Dimension;
	T4?: Dimension;
	T5?: Dimension;
}

export interface SideDimensions {
	S1?: Dimension;
	S2?: Dimension;
	S3?: Dimension;
	S4?: Dimension;
	S5?: Dimension;
}

export interface FrontDimensions {
	F1?: Dimension;
	F2?: Dimension;
	F3?: Dimension;
	F4?: Dimension;
	F5?: Dimension;
}

export interface ICoordinates {
	x: number;
	y: number;
	z: number;
}

export interface ItemDimension {
	height: { [key: string]: Dimension };
	depth: { [key: string]: Dimension };
	width: { [key: string]: Dimension };
	topDimension?: TopDimensions;
	sideDimension?: SideDimensions;
	frontDimension?: FrontDimensions;
}
