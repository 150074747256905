import { CreateComponent } from './create/create.component';
import { ProjectsComponent } from './projects/projects.component';
import { DetailComponent } from './detail/detail.component';


export const Pages = [
	ProjectsComponent,
	CreateComponent,
	DetailComponent,
];
