import { Injectable } from "@angular/core";
import {map, mergeAll, switchMap, take, tap} from "rxjs/operators";
import {
	deleteAllEntities,
	deleteEntities,
	updateEntities,
	upsertEntities
} from "@ngneat/elf-entities";
import { select, setProp, setProps } from "@ngneat/elf";
import { combineLatestWith, forkJoin, Observable } from "rxjs";
import { updateRequestStatus } from "@ngneat/elf-requests";
import { pick } from "ramda";

import { ArticleService } from "~core/services/article.service";
import {Article, Item, IItemPosition, Outline, IItem} from "~shared/types";
import { appStore } from "~shared/store/app/app.store";
import {IItemDimensionIn, UpsertDividerDto, ItemDto, CreateItemDto} from "~modules/project/store/items/item.dto.types";
import {
	CreateConnectionHardwareDTO,
	UpdateConnectionDTO,
	UpdateConnectionHardwareVariantDTO
} from "~core/services/dto/article.dto";
import { itemPositionsStore } from "~modules/project/store/item-positions/item-positions.store";
import { itemsSelector } from "~modules/project/store/items/items.selectors";
import { OutlineType } from "~shared/enums";
import { PartsRepository } from "~modules/project/store/parts/parts.repository";

import {ConfiguratorRepository} from "~modules/configurator/store/configurator/configurator.repository";
import {getOutlineSetLabel} from "~modules/configurator/helpers/outlineSetLabel";
import {RenderedEntityBuilder} from "~shared/builder/rendered.entity.builder";
import {ObjService} from "~shared/services/obj.service";
import {MaterialService} from "~shared/services/material.service";

import { itemsStore } from "./items.store";



@Injectable()
export class ItemsRepository {
	constructor(
		public readonly articleService: ArticleService,
		private partsRepository: PartsRepository,
		private configuratorRepository: ConfiguratorRepository,
		private objService: ObjService,
		private materialService: MaterialService ) {
	}
	public items$ = itemsSelector.items$;
	public itemsLoading$ = itemsSelector.itemsLoading$;
	public deletePanelLoading$ = itemsSelector.deletePanelLoading$;
	public deleteArticleLoading$ = itemsSelector.deleteArticleLoading$;
	public recreateBackLoading$ = itemsSelector.recreateBackLoading$;
	public outlineMap$ = itemsStore.pipe(select((state) => state.outlineMap))
	public dimensions$ = itemsStore.pipe(select((state) => state.newItem.dimension))
	public activePartItems$: Observable<Item[]> = this.items$.pipe(
		combineLatestWith(this.partsRepository.activePart$),
		map(([items, part]) => {return items.filter(item => part?.id === item?.partId)})
	);

	private renderEntityBuilder = new RenderedEntityBuilder(this.objService, this.materialService);

	public static decomposeItemDTO(dto: ItemDto): [Item, IItemPosition] {
		const item = new Item(dto);

		const itemPosition: IItemPosition = {
			id: dto.id,
			partCoordinate: dto.partCoordinate,
			partRotation: dto.partRotation
		};

		return [item as Item, itemPosition];
	}

	public getActivePartItems(): Item[] {
		const items = itemsStore.getValue().entities;
		return Object.values(items).filter(item => item.partId === this.partsRepository.getActivePartId())
	}

	public getItems(partIds: string[]): Observable<Item[]> {
		appStore.update(setProp("loading", true));

		return this.articleService.getItemsByPartIds(partIds)
			.pipe(
				take(1),
				map(items => items.map(ItemsRepository.decomposeItemDTO)),
				switchMap(items =>
					forkJoin(items.map(([item, itemPosition]) =>
						this.renderEntityBuilder.createItem(item as Partial<IItem>).pipe(
							map(createdItem => [createdItem, itemPosition])
						)
					))
				),
				tap(items => {
					appStore.update(setProp("loading", false));
					items.forEach(([item, itemPosition]) => {
						itemPositionsStore.update(upsertEntities(itemPosition));
						itemsStore.update(upsertEntities(item));
					});
				}),
				map(items => items.map(([item, _]) => item as Item))
			);
	}

	public getPartItems(partId: string): Observable<Item[]> {
		return this.getItems([partId]);
	}

	public createNewItem(item: Partial<Item>): void {
		itemsStore.update(
			upsertEntities(item),
			setProps({
				newItem: {
					itemType: item.itemType,
					name: item.name,
					// dimension: item.outline.dimension,
					dimension: undefined,
					outlineSetLabel: ''
				}
			})
		);
	}

	public saveNewItem(values): void {
		appStore.update(setProp("loading", true));
		const tempItem = itemsStore.getValue().entities["new"];

		this.articleService
			.createItem(tempItem.partId, {
				dimension: values.dimensions,
				outlineSetLabel: getOutlineSetLabel(tempItem),
				...pick(["itemType", "name"])(tempItem)
			})
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)))
			.subscribe(([item, itemPosition]) => {
				itemsStore.update(setProps({
					newItem: null
				}));
				appStore.update(setProp("loading", false));
				itemsStore.update(
					deleteEntities("new"),
					upsertEntities(item),
					updateRequestStatus("items", "success")
				);
				itemPositionsStore.update(upsertEntities(itemPosition));
			});
	}

	public deleteItem(partId: string, itemId: string): Observable<void> {
		appStore.update(setProp("loading", true));

		return this.articleService
			.deleteItem(partId, itemId)
			.pipe(
				take(1),
				tap(() => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						deleteEntities(itemId));
					itemPositionsStore.update(deleteEntities(itemId));
				})
			);
	}

	public duplicateItem(partId: string, itemId: string): Observable<Item> {
		itemsStore.update(updateRequestStatus("create-divider", "pending"));
		appStore.update(setProp("loading", true));

		return this.articleService
			.duplicateItem(partId, itemId)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("create-divider", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public createDivider(partId: string, itemId: string, articleZoneId: string, body: UpsertDividerDto): Observable<Article[]> {
		itemsStore.update(updateRequestStatus("create-divider", "pending"));
		appStore.update(setProp("loading", true));

		return this.articleService
			.createDivider(partId, itemId, articleZoneId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				combineLatestWith(this.configuratorRepository.selectedItem$),
				map(([[item, itemPosition], selectedItem]) => {
					appStore.update(setProp("loading", false));
					const articles = this.findChangedArticles(selectedItem, item);
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("create-divider", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
					return articles;
				})
			);
	}

	public createArticleInArticleZone(partId: string, itemId: string, articleZoneId: string, articleType: string, body): Observable<Item> {
		appStore.update(setProp("loading", true));
		return this.articleService
			.createArticleInArticleZone(partId, itemId, articleZoneId, articleType, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(upsertEntities(item));
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public createShelf(partId: string, itemId: string, articleZoneId: string, body){
		itemsStore.update(updateRequestStatus("create-shelf", "pending"));
		appStore.update(setProp("loading", true));
		this.articleService
			.createShelf(partId, itemId, articleZoneId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("create-shelf", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
					this.configuratorRepository.refreshSelectedEntityInstances(item)
				}),
			);
	}

	public createDoor(partId: string, itemId: string, body): Observable<Item> {
		itemsStore.update(updateRequestStatus("create-door", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.createDoor(partId, itemId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("create-door", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public createPanelCutout(partId: string, itemId: string,  panelId: string, cutoutType: string): Observable<Item> {
		itemsStore.update(updateRequestStatus("create-panel-cutout", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.createPanelCutout(partId, itemId, panelId, cutoutType)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("create-panel-cutout", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item));
	}

	public deletePanelCutout(partId: string, itemId: string, panelId: string, cutoutId: string): Observable<Item> {
		itemsStore.update(updateRequestStatus("delete-panel-cutout", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.deletePanelCutout(partId, itemId, panelId, cutoutId)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("delete-panel-cutout", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updatePanelCutout(partId: string, itemId: string, panelId: string, cutoutId: string, body): Observable<Item> {
		itemsStore.update(updateRequestStatus("update-panel-cutout", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updatePanelCutout(partId, itemId, panelId, cutoutId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-panel-cutout", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updateHardware(partId: string, itemId: string, hardwareId: string, body): Observable<Item> {
		itemsStore.update(updateRequestStatus("update-panel-cutout", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updateHardware(partId, itemId, hardwareId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-panel-cutout", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updateArticle(partId: string, itemId: string, articleId: string, body): Observable<Item> {
		itemsStore.update(updateRequestStatus("update-article", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updateArticle(partId, itemId, articleId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-article", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updateConnectionHardwareVariant(
		partId: string,
		itemId: string,
		panelId: string,
		connectionType: string,
		body: UpdateConnectionHardwareVariantDTO
	): Observable<Item> {
		appStore.update(setProp("loading", true));
		itemsStore.update(updateRequestStatus("updateConnectionHardwareVariant", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updateConnectionHardwareVariant(partId, itemId, panelId, connectionType, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("updateConnectionHardwareVariant", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updateConnection(
		partId: string,
		itemId: string,
		panelId: string,
		connectionType: string,
		body: UpdateConnectionDTO
	): Observable<Item> {
		itemsStore.update(updateRequestStatus("updateConnection", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updateConnection(partId, itemId, panelId, connectionType, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("updateConnection", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public createConnectionHardware(
		partId: string,
		itemId: string,
		panelId: string,
		connectionType: string,
		body: CreateConnectionHardwareDTO
	): Observable<Item> {
		itemsStore.update(updateRequestStatus("createConnectionHardware", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.createConnectionHardware(partId, itemId, panelId, connectionType, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("createConnectionHardware", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updateDivider(partId: string, itemId: string, articleId: string, body: UpsertDividerDto): Observable<Article> {
		itemsStore.update(updateRequestStatus("update-divider", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updateDivider(partId, itemId, articleId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));

					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-divider", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => this.findArticle(articleId, item))
			);
	}

	public updateShelf(partId: string, itemId: string, articleId: string, body): Observable<Article> {
		itemsStore.update(updateRequestStatus("update-shelf", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updateShelf(partId, itemId, articleId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-shelf", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => this.findArticle(articleId, item))
			);
	}

	public updatePanelParameter(partId: string, itemId: string, panelId: string, body): Observable<Item> {
		itemsStore.update(updateRequestStatus("update-panel-parameter", "pending"));
		appStore.update(setProp("loading", true));

		return this.articleService
			.updatePanelParameter(partId, itemId, panelId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-panel-parameter", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updatePanel(partId: string, itemId: string, panelId: string, body): Observable<Item> {
		itemsStore.update(updateRequestStatus("update-panel", "pending"));
		appStore.update(setProp("loading", true));

		return this.articleService
			.updatePanel(partId, itemId, panelId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-panel", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updateArticleParameter(partId: string, itemId: string, articleId: string, body): Observable<Item> {
		itemsStore.update(updateRequestStatus("update-article-parameter", "pending"));
		appStore.update(setProp("loading", true));

		return this.articleService
			.updateArticleParameter(partId, itemId, articleId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-article-parameter", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updateFillerConstruction(partId: string, itemId: string, outlineFaceIdx: number, body): Observable<Item> {
		itemsStore.update(updateRequestStatus("update-filler-construction", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updateFillerConstruction(partId, itemId, outlineFaceIdx, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-filler-construction", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public deleteArticle(partId: string, itemId: string, articleId: string): Observable<Item> {
		itemsStore.update(updateRequestStatus("delete-article", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.deleteArticle(partId, itemId, articleId)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("delete-article", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updateItemDimension(itemId: string, partId: string, dimensions: IItemDimensionIn): Observable<Item> {
		itemsStore.update(updateRequestStatus("update-item-dimension", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updateItemDimension(itemId, partId, dimensions)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-item-dimension", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public getItem(itemId: string, partId: string): Observable<Item> {
		itemsStore.update(updateRequestStatus("get-item", "pending"));
		appStore.update(setProp("loading", true));

		return this.articleService.getItem(itemId, partId).pipe(
			take(1),
			map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
			switchMap(([item, itemPosition]) =>
				this.renderEntityBuilder.createItem(item).pipe(
					map(createdItem => [createdItem, itemPosition])
				)
			),
			tap(([item, itemPosition]) => {
				appStore.update(setProp("loading", false));
				itemsStore.update(
					upsertEntities([item]),
					updateRequestStatus("get-item", "success")
				);
				itemPositionsStore.update(upsertEntities(itemPosition));
			}),
			map(([item, _]) => item as Item)
		);
	}


	public updateItem(itemId: string, partId: string, body): Observable<Item> {
		itemsStore.update(updateRequestStatus("update-item", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updateItem(itemId, partId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-item", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updateItemName(itemId: string, partId: string, name): Observable<Item> {
		itemsStore.update(updateRequestStatus("update-item", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updateItemName(itemId, partId, name)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-item", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public addItemFiller(itemId: string, partId: string, fillerType: string, baseType: string, outlineFaceIdx: number): Observable<Item> {
		itemsStore.update(updateRequestStatus("add-item-filler", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.addItemFiller(itemId, partId, fillerType, baseType, outlineFaceIdx)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						updateEntities(item?.id, item),
						updateRequestStatus("add-item-filler", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public removeItemFiller(itemId: string, partId: string, outlineFaceIdx: number): Observable<Item> {
		itemsStore.update(updateRequestStatus("add-item-filler", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.removeItemFiller(itemId, partId, outlineFaceIdx)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						updateEntities(itemId, item),
						updateRequestStatus("remove-item-filler", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updatePanelGroupBoard(partId: string, itemId: string, panelGroup: string, body, panelLabel?: string): Observable<Item> {
		itemsStore.update(updateRequestStatus("update-panel-group-board", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updatePanelGroupBoard(partId, itemId, panelGroup, body, panelLabel)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-panel-group-board", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public recreateBack(partId: string, itemId: string, articleZoneId: string, faceIdx: number): Observable<Item> {
		itemsStore.update(updateRequestStatus("recreate-back", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.recreateBack(partId, itemId, articleZoneId, faceIdx)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("recreate-back", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updatePartPanelGroupBoard(partId: string, panelGroup: string, body, panelLabel?: string): Observable<Item[]> {
		itemsStore.update(updateRequestStatus("update-panel-group-board", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updatePartPanelGroupBoard(partId, panelGroup, body, panelLabel)
			.pipe(
				take(1),
				map(items => items.map(ItemsRepository.decomposeItemDTO)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-panel-group-board", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(items => items.map(([item, _]) => item))
			);
	}

	public updatePanelBoard(partId: string, itemId: string, panelId: string, body): Observable<Item> {
		itemsStore.update(updateRequestStatus("update-panel-board", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updatePanelBoard(partId, itemId, panelId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-panel-board", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public deletePanel(partId: string, itemId: string, panelId: string): Observable<Item> {
		itemsStore.update(updateRequestStatus("delete-panel", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.deletePanel(partId, itemId, panelId)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("delete-panel", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public updatePanelConnection(partId: string, itemId: string, panelId: string, connectionType: string, body): Observable<Item> {
		itemsStore.update(updateRequestStatus("update-panel-cutout", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.updatePanelConnection(partId, itemId, panelId, connectionType, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("update-panel-cutout", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public deleteHardwareFromPanel(partId: string, itemId: string, articleId: string, panelId: string): Observable<Item> {
		itemsStore.update(updateRequestStatus("delete-hardware-from-panel", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.deleteHardwareFromPanel(partId, itemId, panelId)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("delete-hardware-from-panel", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public addHardwareToPanel(partId: string, itemId: string, panelId: string, body): Observable<Item> {
		itemsStore.update(updateRequestStatus("add-hardware-to-panel", "pending"));
		appStore.update(setProp("loading", true));
		return this.articleService
			.addHardwareToPanel(partId, itemId, panelId, body)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item),
						updateRequestStatus("add-hardware-to-panel", "success")
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public addCustomisationToPanel(partId: string, itemId: string, panelId: string, customisationId: string): Observable<Item> {
		appStore.update(setProp("loading", true));
		return this.articleService
			.addCustomisationToPanel(partId, itemId, panelId, customisationId)
			.pipe(take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item)
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public addCustomisationToLayout(partId: string, itemId: string, articleZoneId: string, customisationId: string): Observable<Item> {
		appStore.update(setProp("loading", true));
		return this.articleService
			.addCustomisationToLayout(partId, itemId, articleZoneId, customisationId)
			.pipe(take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item)
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public addCustomisationToItem(partId: string, itemId: string, customisationId: string): Observable<Item> {
		appStore.update(setProp("loading", true));
		return this.articleService
			.addCustomisationToItem(partId, itemId, customisationId)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item)
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public deleteCustomisationFromPanel(partId: string, itemId: string, panelId: string, customisationId: string): Observable<Item> {
		appStore.update(setProp("loading", true));
		return this.articleService
			.deleteCustomisationFromPanel(partId, itemId, panelId, customisationId)
			.pipe(take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item)
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public deleteCustomisationFromLayout(partId: string, itemId: string, articleZoneId: string, customisationId: string): Observable<Item> {
		appStore.update(setProp("loading", true));
		return this.articleService
			.deleteCustomisationFromLayout(partId, itemId, articleZoneId, customisationId)
			.pipe(take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item)
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	public deleteCustomisationFromItem(partId: string, itemId: string, customisationId: string): Observable<Item> {
		appStore.update(setProp("loading", true));
		return this.articleService
			.deleteCustomisationFromItem(partId, itemId, customisationId)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, itemPosition]) => {
					appStore.update(setProp("loading", false));
					itemsStore.update(
						upsertEntities(item)
					);
					itemPositionsStore.update(upsertEntities(itemPosition));
				}),
				map(([item, _]) => item)
			);
	}

	// public activateItem(itemId: string): void {
	// 	itemsStore.update(
	// 	);
	// }

	public clearItems(): void {
		itemsStore.update(
			deleteAllEntities()
		);
		itemPositionsStore.update(
			deleteAllEntities()
		);
	}

	private findArticle(oldArticleId: string, newItem: Item): Article {
		return newItem.articles
			.find((article) => article.id === oldArticleId);
	}

	private findChangedArticles(oldItem: Item, newItem: Item): Article[] {
		return newItem.articles
			.filter((article) => !oldItem.articles.find((oldArticle) => oldArticle.id === article.id));
	}

	public setOutline(outlineType: OutlineType, outline: Outline): void {
		itemsStore.update(
			setProps({
				outlineMap: {
					...itemsStore.getValue().outlineMap,
					[outlineType]: outline
				}
			})
		);
	}

	public setOutlineMap(outlineMap: Record<OutlineType, Outline>): void {
		itemsStore.update(
			setProps({
				outlineMap
			})
		);
	}

	public setDimensions(newDimensions: IItemDimensionIn): void {
		itemsStore.update(
			setProps(({ newItem }) => ({
				newItem: newItem
					? {
						...newItem,
						dimension: {
							...newItem.dimension,
							...newDimensions
						}
					}
					: null
			}))
		);
	}

}
