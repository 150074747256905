import {
	HardwareColour,
	Finish,
	HardwareHandleType,
	HardwareMount,
	HardwareStyle,
	HardwareType,
} from "~shared/enums";
import { ISize } from "~shared/types/size.types";
import { IObject, IObjPosition } from "~shared/types/obj.types";
import { RenderedEntity } from "~shared/types/rendered-object.types";
import { ConGroup, ConThreeObject } from "~shared/types/three.types";

import { ICoordinates } from "./dimension.types";

export interface HardwareItem {
	// This is hardware that is being stored in the hardwareservice
	hardwareType: HardwareType;
	name: string;
	label: string;
	description: string;
	isFavourite?: boolean;
	variantList?: HardwareItemVariant[];
	size: ISize;
	obj: IObject;
	handleMount: HardwareMount;
	handleType: HardwareHandleType;
	style: HardwareStyle;
}


export interface HardwareItemObject {
	label: string;
	insertion_point?: ICoordinates;
	rotation?: ICoordinates;
	offset?: ICoordinates;
	size?: ICoordinates
}

export interface HardwareItemVariant {
	sku: string;
	supplier: string;
	supplierSku: string;
	colour: HardwareColour | Finish;
	image_filenames: string[]
	description: string;
}

interface HardwareCatalogItem {
	hardwareType: HardwareType;
	name: string;
	label: string;
	description: string;
	variantList: HardwareItemVariant[];
	obj?: HardwareItemObject;
}


export class HardwareInterface extends RenderedEntity {
	// This is hardware that is referenced by panels / articles
	sku: string;
	catalogItem: HardwareCatalogItem;
	variant: HardwareItemVariant;
	objPositions: IObjPosition[];
	variantList: HardwareItemVariant[];
	style: HardwareStyle;
	// Rendered object
	renderedObjects: ConThreeObject[] = []

	get childRenderedEntities(): RenderedEntity[] {
		return [];
	}

	get hashCode() {
		return this.sku;
	}

	constructor(data: Partial<HardwareInterface>) {
		super(data);
	}

	protected setObjectGroups() {
		this._objectGroups = []
		this.renderedObjects.forEach(rObject => {
			if (!(rObject instanceof ConGroup)) {
				throw new Error("Type error: rendered objects in hardware interface should be instances of Congroup")
			}
			this._objectGroups.push(rObject);
		}
		)
	}

}
