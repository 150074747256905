import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

import { CatalogItem } from 'src/app/shared/types';

interface ItemProps {
	newItem: null | CatalogItem;
}

export const catalogItemStore = createStore(
	{ name: 'catalog-items' },
	withEntities<CatalogItem>(),
	withActiveId(),
	withProps<ItemProps>({ newItem: null }), //TODO: is this needed for catalogitems?
	withRequestsStatus<string>()
);
