import { createStore, withProps } from '@ngneat/elf';
import {withActiveId, withEntities} from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

import {IItem, Item, Outline} from "src/app/shared/types";
import { CreateItemDto } from "~modules/project/store/items/item.dto.types";
import { OutlineType } from "~shared/enums";

interface ItemProps {
	newItem: null | CreateItemDto;
	outlineMap: null | Record<OutlineType, Outline>;
}

export const itemsStore = createStore(
	{ name: 'item' },
	withEntities<Item>(),
	withProps<ItemProps>({
		newItem: null,
		outlineMap: null,
	}),
	withRequestsStatus<string>()
);
