import {CuttingListNavigatorComponent} from "./cutting-list-navigator/cutting-list-navigator.component";
import {ProjectHeaderComponent} from "./project-header/project-header.component";
import {ProjectNavigatorComponent} from "./project-navigator/project-navigator.component";
import {NavigationPanelComponent} from "./navigation-panel/navigation-panel.component";


export const Components = [
	ProjectNavigatorComponent,
	ProjectHeaderComponent,
	CuttingListNavigatorComponent,
	NavigationPanelComponent
];
