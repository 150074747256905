import { BufferGeometry, BufferGeometryUtils, Float32BufferAttribute, Material } from "three";

import { ConThreeObject } from "~shared/types/three.types";
import { computeGeometryProperties } from "~shared/scenes/helpers";
import { IMeshMaterial } from "~shared/types/material.types";

import { FaceNormal } from "../enums";
import {mergeBufferGeometries} from "~shared/builder/utils";


import { RenderedEntity } from "~shared/types/rendered-object.types";


export class Face extends RenderedEntity {
	// Entity
	readonly faceIdx: number;
	readonly faceNormal: FaceNormal;
	readonly vertices: [number, number, number][][];
	mesh?: IMeshMaterial;
	// Rendered object
	renderedObjects: ConThreeObject[] = [];
	defaultMaterial: Material = undefined;
	#bufferGeometry: BufferGeometry;
	#bufferGeometries: BufferGeometry[] = [];
	#hashCode: string

	get childRenderedEntities() {
		return []
	}

	get hashCode(): string {
		if (this.#hashCode) return this.#hashCode;

		const prime = 31;
		let hash = 1;
		hash = prime * hash + (this.faceIdx ?? 0);
		hash = prime * hash + (this.faceNormal ? this.faceNormal.toString().length : 0);

		// Include vertices for a shape-specific element
		const verticesHash = this.vertices
			.flat(2)
			.reduce((acc, value) => acc + value, 0);
		this.#hashCode = (prime * hash + verticesHash).toString();
		return this.#hashCode;
	}

	get bufferGeometry() {
		if (!this.#bufferGeometry) this.setBufferGeometry()
		return this.#bufferGeometry
	}

	get bufferGeometries() {
		// Use this in case you want to get the list of buffergeometries to merge
		// them together with a single call at a higher level
		if (!this.#bufferGeometries) this.setBufferGeometries()
		return this.#bufferGeometries
	}

	constructor(data) {
		super(data)
		this.setBufferGeometries()
		this.setBufferGeometry()
	}

	private setBufferGeometries() {
		for (let j = 0; j < this.vertices.length; j++) {
			const geometry = new BufferGeometry();
			const positions = [
				this.vertices[j][0][0],
				this.vertices[j][0][1],
				this.vertices[j][0][2], // v1
				this.vertices[j][1][0],
				this.vertices[j][1][1],
				this.vertices[j][1][2], // v2
				this.vertices[j][2][0],
				this.vertices[j][2][1],
				this.vertices[j][2][2] // v3
			];

			geometry.setAttribute(
				"position",
				new Float32BufferAttribute(positions, 3)
			);
			this.#bufferGeometries.push(geometry);
		}
	}

	private setBufferGeometry() {
		this.#bufferGeometry = mergeBufferGeometries(this.bufferGeometries)
		computeGeometryProperties(this.#bufferGeometry)
	}
}
