import { Injectable } from '@angular/core';
import { updateRequestStatus } from '@ngneat/elf-requests';
import { take, tap } from 'rxjs/operators';
import { upsertEntities, setActiveId, addEntities, setEntities, resetActiveId, deleteAllEntities, deleteEntities } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';

import { ProjectService } from '~core/services/project.service';
import { Project } from '~shared/types';
import { ProjectStatus } from '~shared/enums';

import { projectStore } from './projects.store';
import { projectsSelector } from './projects.selectors';

@Injectable()
export class ProjectsRepository {
	public projects$ = projectsSelector.projects$;
	public activeProject$ = projectsSelector.activeProject;
	public projectsLoading$ = projectsSelector.projectsLoading$;

	constructor(private readonly projectService: ProjectService) {}

	public getProjects(accountId?: string): void {
		projectStore.update(updateRequestStatus('projects', 'pending'));
		this.projectService
			.getProjects(accountId)
			.pipe(take(1))
			.subscribe((projects) =>
				projectStore.update(
					setEntities(projects),
					updateRequestStatus('projects', 'success')
				)
			);
	}

	public getProject(projectId: string): void {
		projectStore.update(updateRequestStatus(`project-${projectId}`, 'pending'));
		this.projectService
			.getProject(projectId)
			.pipe(take(1))
			.subscribe((project) =>
				projectStore.update(
					setActiveId(projectId),
					upsertEntities(project),
					updateRequestStatus(`project-${projectId}`, 'success')
				)
			);
	}

	public updateProject(projectId: string, project: Partial<Project | {name: string, accountId: string}>): Observable<Project> {
		projectStore.update(updateRequestStatus('projects', 'pending'));
		return this.projectService
			.updateProject(projectId, project)
			.pipe(
				tap((project) => {
					projectStore.update(
						upsertEntities(project),
						updateRequestStatus('projects', 'success')
					)
				})
			)
	}

	public updateProjectStatus(projectId: string, status: ProjectStatus): Observable<Project> {
		projectStore.update(updateRequestStatus('projects', 'pending'));
		return this.projectService
			.updateProjectStatus(projectId, status)
			.pipe(
				tap((project) => {
					projectStore.update(
						upsertEntities(project),
						updateRequestStatus('projects', 'success')
					)
				})
			)
	}

	public deleteProject(projectId: string): Observable<void> {
		projectStore.update(updateRequestStatus('projects', 'pending'));
		return this.projectService
			.deleteProject(projectId)
			.pipe(
				tap(() => {
					projectStore.update(
						deleteEntities(projectId),
						updateRequestStatus('projects', 'success')
					)
				})
			)
	}

	public duplicateProject(projectId: string): Observable<Project> {
		projectStore.update(updateRequestStatus('projects', 'pending'));
		return this.projectService
			.duplicateProject(projectId)
			.pipe(
				tap((project) => {
					projectStore.update(
						upsertEntities(project),
						updateRequestStatus('projects', 'success')
					)
				})
			)
	}

	public clearProjects(): void {
		projectStore.update(
			resetActiveId(),
			deleteAllEntities()
		);
	}
}
