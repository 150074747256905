import { DividerType, PlaneAxis } from "../enums";

import { ILinearDivision } from "./linear-division";


export interface IDivider {
	linearDivision: ILinearDivision,
	axis: PlaneAxis,
	dividerType: DividerType
}
