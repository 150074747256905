import { LineBasicMaterial } from "three";

import { FaceNormal, FrontConnection, RenderedObjectTypeEnum } from "~shared/enums";
import { IEdge } from "~shared/types/edge.types";
import { Polyhedron } from "~shared/types/polyhedron.types";
import { BackPanelType, PanelGroup, PanelType } from "~shared/enums/panel.enum";
import { validateFields } from "~shared/helpers";
import { IConstruction } from "~shared/types/construction.types";
import { RenderedEntity } from "~shared/types/rendered-object.types";
import { ConGroup, ConThreeObject } from "~shared/types/three.types";

import { FinishedBoard } from "./boards.types";
import { ICutout } from "./cutout.types";
import { IParameterSet } from "./parameter.types";
import { Connection } from "./connection.types";


export class Panel extends RenderedEntity {
	// Entity
	id: string;
	parentId: string;
	polyhedron: Polyhedron;
	sequence?: number;
	cutouts?: ICutout[] = [];
	backPanelType?: BackPanelType;
	disMountable?: boolean;
	edges?: IEdge[] = [];
	panelType?: PanelType;
	parameterSet?: IParameterSet;
	removable?: boolean;
	panelGroup?: PanelGroup;
	outlineFaceIdx?: number;
	outlineFaceNormal?: FaceNormal;
	construction?: IConstruction;
	board?: FinishedBoard;
	frontConnection?: FrontConnection;
	hingeConnection?: Connection;
	handleConnection?: Connection;
	// Rendered objects
	renderedObjects: ConThreeObject[] = [];
	defaultMaterial: LineBasicMaterial;

	get childRenderedEntities(): RenderedEntity[] {
		return [
			...this.polyhedron.faces,
			...this.handleConnection?.hardware
		]
	}

	get hashCode(){return this.id}

	constructor(
		data: Partial<Panel>
	) {
		validateFields(data, [{key: "polyhedron", instanceType: Polyhedron}])
		super(data)
	}

	protected setObjectGroups() {
		this._objectGroups = []
		const selfGroup = new ConGroup(RenderedObjectTypeEnum.ENTITY, this)
		// Add lines to selfgroup
		this.renderedObjects.forEach(rObject => selfGroup.add(rObject))

		// Need to make sure hardware can be identified to the panel, so need to add to the selfgroup
		if (this.handleConnection && this.handleConnection.hardware.length) {
			this.handleConnection.hardware.forEach(hardware => {
				hardware.objectGroups.forEach(hwGroup => selfGroup.add(hwGroup))
			})
		}
		this._objectGroups.push(selfGroup)
	}
}

