import {combineLatest, forkJoin, Observable, of, Subject, throwError} from "rxjs";
import { switchMap, take, takeUntil, map, catchError, filter} from 'rxjs/operators';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Article, Item} from '~shared/types';
import { ParameterValidators } from '~shared/validators/parameters';
import { ItemsRepository } from '~modules/project/store/items/items.repository';
import { ConfiguratorRepository } from '~modules/configurator/store/configurator/configurator.repository';
import { ParameterValue } from '~shared/shared.types';
import {ConstructionParameter} from '~shared/enums';
import {AppRepository} from "~shared/store";

interface ParameterMeta {
	label: string;
	parameterType: ConstructionParameter;
	inputType: string;
	level?: string;
}

@Component({
	selector: 'app-construction-parameter-input',
	templateUrl: './construction-parameter-input.component.html',
})
export class ConstructionParameterInputComponent implements OnInit, OnDestroy {
	@Input() name: string;
	@Input() parameters: ParameterMeta[] = [];
	@Input() selectedItem: Observable<Article>;
	@Input() infoImage?: string;
	@Input() infoText?: string;

	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	public availableParameters: (ParameterValue & ParameterMeta)[] = [];
	public panelType = null;
	public disabled = false;
	public show = true;
	public isOpen = true;
	public formGroup: FormGroup = new FormGroup({});
	public selectedParamLevel: string;

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly configuratorRepository: ConfiguratorRepository,
		private readonly appRepository: AppRepository
	) {}

	public ngOnInit(): void {
		this.initializeDefaults();

		this.configuratorRepository.selectedPanel$
			.pipe(
				takeUntil(this.componentDestroyed$),
				map((item) => item),
				filter((item) => !!item),
			)
			.subscribe((item) => this.panelType = item.panelType);

		this.configuratorRepository.disableFields$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((disabled) => this.disabled = disabled);
	}

	@HostListener('document:keydown.escape')
	public onKeydownHandler() {
		this.initializeDefaults()
	}

	public submitForm(): void {
		if (!this.formGroup.valid && !this.formGroup.dirty) {
			return
		}

		this.appRepository.setLoading(true);
		combineLatest([this.configuratorRepository.selectedItem$, this.configuratorRepository.selectedPanel$, this.configuratorRepository.selectedArticle$])
			.pipe(
				take(1),
				switchMap(([item, panel, article]) => {
					const availableParams = this.availableParameters;

					// Group available parameters by level
					const paramsByLevel = availableParams.reduce((acc, param) => {
						acc[param.level] = acc[param.level] || [];
						acc[param.level].push(param);
						return acc;
					}, {} as Record<string, typeof availableParams>);

					return forkJoin(
						Object.entries(paramsByLevel).map(([level, params]) => {
							const filteredParams = params
								.filter((param) => {
									return !!this.formGroup.value[param.parameterType] && !this.formGroup.get(param.parameterType).pristine})
								.map((param) => ({
									value: Number(this.formGroup.value[param.parameterType]),
									parameterType: param.parameterType
								}));

							if (level === 'panel' && filteredParams?.length) {
								return this.itemsRepository.updatePanelParameter(
									item.partId,
									item.id,
									panel.id,
									filteredParams
								);
							} else if (level === 'article' && filteredParams?.length) {
								// TODO check if this is used
								return this.itemsRepository.updateArticleParameter(
									item.partId,
									item.id,
									article?.id,
									filteredParams
								);
							} else {
								return of(item);
							}
						})
					).pipe(take(1), map(() => {
						this.selectedParamLevel = null;
						return {panel, article};
					}))
				}),
				catchError((err) => {
					this.appRepository.setLoading(false)
					return throwError(() => err);
				}),
				switchMap(({panel, article}) =>
					this.configuratorRepository.selectedItem$.pipe(take(1), map((item) => ({ item, panel, article }))))
			)
			.subscribe(({ item, panel, article }) => {
				this.appRepository.setLoading(false);


				if (!panel || !article) {
					return
				}

				this.configuratorRepository.setSelectedEntities([
					panel,
					article]);
			})
	}

	public toggleItem(): void {
		this.isOpen = !this.isOpen
	}

	public initializeDefaults(): void {
		this.selectedParamLevel = null;

		combineLatest([this.configuratorRepository.selectedItem$, this.configuratorRepository.selectedPanel$, this.configuratorRepository.selectedArticle$])
			.pipe(
				takeUntil(this.componentDestroyed$),
			)
			.subscribe(([item, panel, article ]) => {
				this.formGroup = new FormGroup({});

				const mappedParameters: (ParameterValue & ParameterMeta)[] = (this.parameters || [])
					.map((parameterMeta: ParameterMeta) => {
						const panelLevelParam: ParameterValue = panel?.parameterSet?.parameters?.find(({ parameterType }) => parameterMeta.parameterType === parameterType);
						if (panelLevelParam) {
							return { ...panelLevelParam, ...parameterMeta, level: 'panel' }
						}
						const articleLevelParam =  article?.parameterSet?.parameters?.find(({ parameterType }) => parameterMeta.parameterType === parameterType);
						if (articleLevelParam) {

							return { ...articleLevelParam, ...parameterMeta, level: 'article' }
						}
					})
					.filter((param) => !!param);

				if (mappedParameters.length < 1) {
					return this.show = false;
				}

				this.show = true;
				this.availableParameters = mappedParameters.map((parameter: (ParameterValue & ParameterMeta)) => {
					this.formGroup.addControl(parameter.parameterType, new FormControl(parameter.value, [ParameterValidators.checkConstraints(parameter.constraint)]));

					this.formGroup.get(parameter.parameterType).enable();
					this.formGroup.get(parameter.parameterType).valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
						this.selectedParamLevel = parameter.level;
					});

					return parameter;
				});
				this.formGroup.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(values => {
					const isDefaultValues = this.availableParameters.filter(param => param.value === values[param.parameterType])
                    if (isDefaultValues.length === this.availableParameters.length) {
						this.selectedParamLevel = null;
					}
				});

			})
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
