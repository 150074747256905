import { Injectable } from '@angular/core';
import { updateRequestStatus } from '@ngneat/elf-requests';
import { take } from 'rxjs/operators';
import { upsertEntities, deleteEntities, setEntities, setActiveId, deleteAllEntities, resetActiveId } from '@ngneat/elf-entities';
import { Observable, tap } from 'rxjs';

import { ProjectService } from '~core/services/project.service';
import { Part } from '~shared/types';

import { partStore } from './parts.store';
import { partsSelector } from './parts.selectors';
import { CreatePartDto, UpdatePartDto } from './parts.types';

@Injectable()
export class PartsRepository {
	public parts$ = partsSelector.parts$;
	public activePart$ = partsSelector.activePart$;
	public partsLoading$ = partsSelector.partsLoading$;

	constructor(
		private readonly projectService: ProjectService,
	) {}

	public getParts({ accountId, projectId }: { accountId?: string, projectId?: string }): Observable<Part[]> {
		partStore.update(updateRequestStatus('parts', 'pending'));
		return this.projectService
			.getParts({ accountId, projectId })
			.pipe(
				take(1),
				tap((parts) =>
					partStore.update(
						setEntities(parts),
						updateRequestStatus('parts', 'success')
					)
				)
			)
	}

	// Generate a method to retrieve the active part from the repository
	public getActivePartId(): string | undefined {
		return partStore.query((store) => store.activeId);
	}


	public getPartHistory(projectId: string, partId: string): Observable<Part[]> {
		partStore.update(updateRequestStatus('parts', 'pending'));
		return this.projectService
			.getPartHistory(projectId, partId)
			.pipe(
				take(1)
			)
	}

	public createPart(part: CreatePartDto): Observable<Part> {
		partStore.update(updateRequestStatus('parts', 'pending'));
		return this.projectService
			.createPart(part)
			.pipe(
				take(1),
				tap((part) =>
					partStore.update(
						upsertEntities(part),
						updateRequestStatus('parts', 'success')
					)
				)
			);
	}

	public updatePart(partId: string, part: UpdatePartDto): Observable<Part> {
		partStore.update(updateRequestStatus('parts', 'pending'));
		return this.projectService
			.updatePart(partId, part)
			.pipe(
				take(1),
				tap((part) => partStore.update(
					upsertEntities({
						...part,
						expectedDeliveryDate: part.expectedDeliveryDate || null
					}),
					updateRequestStatus('parts', 'success')
				))
			)
	}

	public duplicatePart(partId: string): Observable<Part> {
		partStore.update(updateRequestStatus('parts', 'pending'));
		return this.projectService
			.duplicatePart(partId)
			.pipe(
				take(1),
				tap((part) => partStore.update(
					upsertEntities(part),
					updateRequestStatus('parts', 'success')
				))
			)
	}

	public addCustomisationToPart(partId: string, customisationId: string): Observable<Part> {
		partStore.update(updateRequestStatus('add-customisation-to-part', 'pending'));
		return this.projectService
			.addCustomisationToPart(partId, customisationId)
			.pipe(
				take(1),
				tap((part) => partStore.update(
					upsertEntities(part),
					updateRequestStatus('add-customisation-to-part', 'success')
				))
			)
	}

	public deleteCustomisationFromPart(partId: string, customisationId: string): Observable<Part> {
		partStore.update(updateRequestStatus('delete-customisation-from-part', 'pending'));
		return this.projectService
			.deleteCustomisationFromPart(partId, customisationId)
			.pipe(
				take(1),
				tap((part) => partStore.update(
					upsertEntities(part),
					updateRequestStatus('add-customisation-to-part', 'success')
				))
			)
	}

	public updateStatus(partId: string, status: string): Observable<Part> {
		partStore.update(updateRequestStatus('parts', 'pending'));
		return this.projectService
			.updatePartStatus(partId, status)
			.pipe(
				take(1),
				tap((part) =>
					partStore.update(
						upsertEntities(part[0]),
						updateRequestStatus('parts', 'success')
					)
				)
			)
	}

	public deletePart(partId: string): void {
		partStore.update(updateRequestStatus('parts', 'pending'));
		this.projectService
			.deletePart(partId)
			.pipe(take(1))
			.subscribe(() =>
				partStore.update(
					deleteEntities(partId),
					updateRequestStatus('parts', 'success')
				)
			);
	}

	public activatePart(partId: string): void {
		partStore.update(
			setActiveId(partId)
		)
	}

	public clearParts(): void {
		partStore.update(
			deleteAllEntities(),
			resetActiveId(),
		)
	}
}
