import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';

import { ProjectRoutingModule } from './project-routing.module';
import { Pages } from './pages';
import { Modals } from './modals';
import { Components } from './components';
import { Repositories } from './store';
import { Pipes } from './pipes';
import {NavigationPanelComponent} from "~modules/project/components/navigation-panel/navigation-panel.component";
import {BoardsModule} from "~modules/board/boards.module";
@NgModule({
	declarations: [Pages, Components, Modals, Pipes],
	imports: [SharedModule, CommonModule, ProjectRoutingModule, BoardsModule],
	providers: [Repositories],
	exports: [NavigationPanelComponent],
})
export class ProjectModule {}
