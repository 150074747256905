import {
	BufferGeometry,
	EdgesGeometry,
	LineBasicMaterial, LineSegments,
	Object3D,
	Vector3
} from "three";

import { IItemPosition } from "../../types";

export const createLines = (
	objGeometry: BufferGeometry,
	material: LineBasicMaterial,
	innerLine: boolean)=> {
	const layoutLineScaleValue = 0.9999;

	const geometry = objGeometry.clone();
	let edges = new EdgesGeometry(geometry);

	const lines = new LineSegments(edges, material);
	lines.renderOrder = 1;

	if (innerLine) {
		const center = new Vector3();
		lines.geometry.computeBoundingBox();
		lines.geometry.boundingBox.getCenter(center);
		lines.geometry.center();
		lines.scale.set(
			layoutLineScaleValue,
			layoutLineScaleValue,
			layoutLineScaleValue
		);
		lines.position.copy(center);
	}

	return lines;
}

export const applyItemPosition = (object: Object3D, itemPosition: IItemPosition): Object3D => {
	object.rotation.x = Number(itemPosition?.partRotation?.x) ?? 0;
	object.rotation.y = Number(itemPosition?.partRotation?.y) ?? 0;
	object.rotation.z = Number(itemPosition?.partRotation?.z) ?? 0;
	object.position.x = Number(itemPosition?.partCoordinate?.x) ?? 0;
	object.position.y = Number(itemPosition?.partCoordinate?.y) ?? 0;
	object.position.z = Number(itemPosition?.partCoordinate?.z) ?? 0;
	return object
}

export const computeGeometryProperties = (bufferGeometry: BufferGeometry) => {
	bufferGeometry.computeBoundingBox();
	bufferGeometry.computeBoundingSphere();
	bufferGeometry.computeVertexNormals();
}
