import { Object3D, Mesh, Group, BufferGeometry, Material, LineSegments } from "three";

import { RenderedObjectTypeEnum } from "../enums";

import { SelectionMeta, RenderedEntity } from "./rendered-object.types";

// Constrained versions of Three objects to enforce userData structure

interface AbstractConUserData {
	type: RenderedObjectTypeEnum;
	selection?: SelectionMeta;
}

interface ConUserDataWithEntity extends AbstractConUserData {
	type: RenderedObjectTypeEnum.ENTITY;
	entity: RenderedEntity; // Required when type is ENTITY
}

interface ConUserDataWithoutEntity extends AbstractConUserData {
	type: Exclude<RenderedObjectTypeEnum, RenderedObjectTypeEnum.ENTITY>;
	entity?: RenderedEntity; // Optional for other types
}

type ConUserData = ConUserDataWithEntity | ConUserDataWithoutEntity;

export type ConThreeObject = ConObject3D | ConMesh | ConGroup | ConLineSegments;

export function isConThreeObject(obj: any): obj is ConThreeObject {
	return (
		obj instanceof Object3D ||
		obj instanceof Mesh ||
		obj instanceof Group ||
		obj instanceof LineSegments
	);
}

export class ConObject3D extends Object3D {
	declare userData: ConUserData;

	constructor(type: RenderedObjectTypeEnum, entity?: RenderedEntity) {
		super();
		this.userData = {
			type,
			entity,
			selection: {} as SelectionMeta,
		};
	}
}

export class ConMesh extends Mesh {
	declare userData: ConUserData;

	constructor(geometry: BufferGeometry, material: Material, type: RenderedObjectTypeEnum, entity?: RenderedEntity) {
		super(geometry, material);
		this.userData = {
			type,
			entity,
			selection: {} as SelectionMeta,
		};
	}
}

export class ConGroup extends Group {
	declare userData: ConUserData;

	constructor(type: RenderedObjectTypeEnum, entity?: RenderedEntity) {
		super();
		this.userData = {
			type,
			entity,
			selection: {} as SelectionMeta,
		};
	}
}

export class ConLineSegments extends LineSegments {
	declare userData: ConUserData;

	constructor(geometry: BufferGeometry, material: Material, type: RenderedObjectTypeEnum, entity?: RenderedEntity) {
		super(geometry, material);
		this.userData = {
			type,
			entity,
			selection: {} as SelectionMeta,
		};
	}
}
