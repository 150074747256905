

export enum ConstructionParameter {
	BASEHEIGHT = 'BASEHEIGHT',
	FRONTBASE_CARCASEOFFSET = 'FRONTBASE_CARCASEOFFSET',
	BACKBASE_CARCASEOFFSET = 'BACKBASE_CARCASEOFFSET',
	BACK_OFFSET = 'BACK_OFFSET',
}

export enum MeasurementUnit {
    MILLIMETRE = "MILLIMETRE",
    CENTIMETRE = "CENTIMETRE",
    SQ_METER = "SQ_METER",
    CUB_METER = "CUB_METER",
    UNIT = "UNIT",
    METER = "METER",
    BOOLEAN = "BOOLEAN",
    MINUTE = "MINUTE",
    HOUR = "HOUR",
    PAIR = "PAIR",
}



export enum ArticleType {
    CARCASE = "CARCASE",
    BASE = "BASE",
    DRAWERS = "DRAWERS",
    DRAWER = "DRAWER",
    SHELVES = "SHELVES",
    FILLER = "FILLER",
    DIVIDER = "DIVIDER",
    FRONT = "FRONT",
    BASERAIL = "BASERAIL",
    BASEFRAME = "BASEFRAME",
    BASEFEET = "BASEFEET",
    PLINTH = "PLINTH",
    FRAME = "FRAME",
    SIDEMOUNTED_ROD = "SIDEMOUNTED_ROD",
    SUSPENDED_ROD = "SUSPENDED_ROD",
}

export enum ItemType {
	STANDING_CABINET = 'STANDING_CABINET',
	HANGING_CABINET = 'HANGING_CABINET',
	MODULAR_CABINET = 'MODULAR_CABINET',
	CABINET = 'CABINET',
	BENCH = 'BENCH',
}

export enum BaseType {
	RAIL = "RAIL",
	FRAME = "FRAME",
	FEET = "FEET"
}

export enum EdgeLocation {
	BACK = 'BACK',
	FRONT = 'FRONT',
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
	TOP = 'TOP',
	BOTTOM = 'BOTTOM',
}

export enum ConstructionType {
	INSET = 'INSET',
	ONSET = 'ONSET',
	MITRE = 'MITRE',
}

export enum PlaneAxis {
	X = 'X', // Vertical
	Y = 'Y', // Horizontal
	Z = 'Z', // Depth
}

export enum ConnectionType {
    DOWEL_SCREW = "DOWEL_SCREW",
    EXCENTER = "EXCENTER",
    SINGLE_ELBOW_EXCENTER = "SINGLE_ELBOW_EXCENTER",
    DOUBLE_ELBOW_EXCENTER = "DOUBLE_ELBOW_EXCENTER",
    SCREW = "SCREW",
    DOWEL = "DOWEL",
    DOWEL_SUPPORT = "DOWEL_SUPPORT",
    SHELVE_SUPPORT = "SHELVE_SUPPORT",
    SHELVE_SUPPORT_PIN = "SHELVE_SUPPORT_PIN",
    GROOVE = "GROOVE",
    GROOVE_STROKE = "GROOVE_STROKE",
    GROOVE_SCREW = "GROOVE_SCREW",
    GLUE = "GLUE",
    DOWEL_GLUE = "DOWEL_GLUE",
    HINGE = "HINGE",
    HANDLE = "HANDLE"
}

export enum FrontConnection {
	LEFT_HINGED = 'LEFT_HINGED',
	RIGHT_HINGED = 'RIGHT_HINGED',
	BOTTOM_HINGED = 'BOTTOM_HINGED',
	TOP_HINGED = 'TOP_HINGED',
	DOUBLE = 'DOUBLE',
	FIXED = 'FIXED',
	DRAWER = 'DRAWER'
}

export enum DivisionMode {
	INCLUDE_DIVIDER = 'INCLUDE_DIVIDER',
	EXCLUDE_DIVIDER = 'EXCLUDE_DIVIDER',
}

export enum BoundaryMode {
	OUTSIDE_LAYOUT = 'OUTSIDE_LAYOUT',
	INSIDE_LAYOUT = 'INSIDE_LAYOUT',
}

export enum FrontType {
	PANEL = 'PANEL',
	METAL = 'METAL',
	GLASS = 'GLASS',
}


export enum HandleLocation {
	VLT = "VLT",
    VLC = "VLC",
    VLB = "VLB",
    HLT = "HLT",
    HCT = "HCT",
    HRT = "HRT",
    HLB = "HLB",
    HCB = "HCB",
    HRB = "HRB",
    VRT = "VRT",
    VRC = "VRC",
    VRB = "VRB",
    HCC = "HCC",
    VCC = "VCC"
}


export enum HingeType {
    HINGE_86 = "HINGE_86",
    HINGE_110 = "HINGE_110",
    HINGE_155 = "HINGE_155",
    HINGE_170 = "HINGE_170",
}


export enum FillerType {
    FRONT = "FRONT",
    RECESSED = "RECESSED",
    PANEL = "PANEL",
    PANEL_RECESSED = "PANEL_RECESSED",
    PANEL_STRIP = "PANEL_STRIP",
    PANEL_STRIP_RECESSED = "PANEL_STRIP_RECESSED",
}
