import { UserType } from "~modules/auth/types/user.types";
import { BoardOverlayType, BoardType } from "~shared/enums";

export const boardTypeValues = (userType: UserType, user?) => [{
	label: "Gebruiksklaar",
	value: BoardType.FINISHED
}, {
	label: "Ruw",
	value: BoardType.RAW
}, ...(userType === UserType.EXTERNAL || user?.account?.overwritten ? [
	{
		label: "Mijn lijst",
		value: BoardType.FAVOURITED
	}
] : [])];

export const boardOverlayTypeValues = () => [{
	label: "Melamine",
	value: BoardOverlayType.MELAMINE
}, {
	label: "Laminaat",
	value: [BoardOverlayType.LAMINATE, BoardOverlayType.CPLAMINATE]
}, {
	label: "Fineer",
	value: BoardOverlayType.VENEER
}, {
	label: "Massief",
	value: BoardOverlayType.SOLID
}, {
	label: "Acryl",
	value: BoardOverlayType.ACRYLIC
}, {
	label: "Backing",
	value: BoardOverlayType.BACKING
}, {
	label: "Primer",
	value: BoardOverlayType.PRIMER
}, {
	label: "Poederlak",
	value: BoardOverlayType.POWDER_COATING
}
];

export const titleCase = (str: string): string => {
	if (!str) {
		return "";
	}

	return str
		.split(" ")
		.map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
		.join(" ");
};
