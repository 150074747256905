import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';

import { AVAILABLE_HANDLE_POSITIONS } from './handle-position-input.const';
import {HandleLocation} from "~shared/enums";

@Component({
	selector: 'app-handle-position-input',
	templateUrl: './handle-position-input.component.html',
	providers: [
		{
			provide: [],
			useExisting: forwardRef(() => HandlePositionInputComponent),
			multi: true,
		},
	],
})
export class HandlePositionInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
	@Input() disabled: boolean;
	@Input() availablePositions: HandleLocation[] = [];

	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	public handlePositions = AVAILABLE_HANDLE_POSITIONS;
	public control: UntypedFormControl = new UntypedFormControl('');
	public updateValue = (_: any) => {};
	public onTouched = () => {};

	constructor(public ngControl: NgControl) {
		ngControl.valueAccessor = this;
	}

	private propagateChange(value: any): void {
		if (this.updateValue) {
			this.updateValue(value);
		}

		if (this.control) {
			this.control.setValue(value, {
				emitEvent: false
			});
		}
	}

	public onBlurField(): void {
		this.onTouched();
	}

	public ngOnInit(): void {
		if (this.disabled) {
			this.control.disable();
		}

		this.control.valueChanges.pipe(
			takeUntil(this.componentDestroyed$),
		).subscribe((value) => {
			this.propagateChange(value);
		});
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public writeValue(value: any): void {
		setTimeout(() => this.control.setValue(value, {
			emitEvent: false
		}));
	}

	public handlePosition(value: string): void {
		this.propagateChange(value);
	}

	public registerOnChange(fn: any): void {
		this.updateValue = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}
}
