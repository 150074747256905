import { Component, Inject, EventEmitter, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, map, Observable, switchMap, take } from 'rxjs';

import { Part } from '~shared/types';
import {PartsRepository} from "../../store/parts/parts.repository";
import { PartStatuses } from "~modules/project/project.const";

@Component({
	templateUrl: "./status-history.modal.html"
})
export class StatusHistoryModalComponent implements OnInit {
	public partStatuses = PartStatuses
	public partHistory: Part[]

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<StatusHistoryModalComponent>,
		public partsRepository: PartsRepository,
	) {}

	public ngOnInit(): void {
		this.partsRepository.getPartHistory(this.data.projectId, this.data.partId)
			.pipe(take(1))
			.subscribe((partHistory) => this.partHistory = partHistory.sort((a, b) => a.status_seq - b.status_seq));
	}
}
