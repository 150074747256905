import { Injectable } from '@angular/core';
import {
	Color,
	Material,
	MeshStandardMaterial,
	ShaderMaterial,
	FrontSide,
	MeshBasicMaterial,
	Mesh,
	LineSegments, Object3D, LineBasicMaterial, Line
} from "three";

import { IMeshMaterial } from '../types';

@Injectable({
	providedIn: 'root',
})
export class MaterialService {
	// Service which assures materials are only created once and groups all other methods concerning materials
	private materialCache = new Map<string, Material>();

	getMaterial(meshMaterial: IMeshMaterial, hatched?: boolean, isLineMaterial: boolean = false): Material|LineBasicMaterial {

		const opacity = Number(meshMaterial?.opacity);
		const formattedOpacity =  !isNaN(opacity) ?  opacity.toFixed(5)?.replace('.', '_') : 1; // Normalize float
		// Include `isLineMaterial` in cache key
		const key = `${meshMaterial?.colour}-${formattedOpacity}-${hatched ? 'hatched' : 'solid'}-${isLineMaterial ? 'line' : 'mesh'}`;

		// Check if the material exists in the cache
		if (!this.materialCache.has(key)) {
			// Create a new material based on the type
			const newMaterial = isLineMaterial
				? MaterialService.lineMaterial(meshMaterial?.colour, meshMaterial?.opacity) // Line material
				: hatched
					? MaterialService.hatchedMaterial(meshMaterial?.colour, meshMaterial?.opacity) // Shader material
					: MaterialService.standardMaterial(meshMaterial?.colour, meshMaterial?.opacity); // Mesh material

			// Cache the newly created material
			this.materialCache.set(key, newMaterial);
		}

		// Return the cached material
		return this.materialCache.get(key)!;
	}

	static cleanMaterial(material: Material | Material[]){
		if (!material) return;

		const materials = Array.isArray(material) ? material : [material];

		materials.forEach(mat => {
			if (!mat) return;

			// Dispose material
			mat.dispose();

			// Dispose textures
			Object.keys(mat).forEach(key => {
				const value = (mat as any)[key];
				if (value && typeof value === 'object' && 'minFilter' in value) {
					value.dispose();
				}
			});
		});
	}

	updateMaterial(rObject: Object3D, newColor?: string, newOpacity?: number) {
		rObject.traverse(iterObject => {
			// Ensure iterObject has a material
			if (
				!(iterObject instanceof Mesh) &&
				!(iterObject instanceof LineSegments) &&
				!(iterObject instanceof Line)
			) {
				return;
			}

			// Handle material arrays or single materials
			const materials = Array.isArray(iterObject.material) ? iterObject.material : [iterObject.material];

			materials.forEach((material) => {
				let currentColor: number;
				let currentOpacity: number;
				let hatched = false;

				// Check for ShaderMaterial
				if (material instanceof ShaderMaterial) {
					currentColor = material.uniforms.baseColor.value.getHex();
					currentOpacity = material.uniforms.opacity.value;
					hatched = true;
				}
				// Check for Mesh materials
				else if (material instanceof MeshStandardMaterial
					|| material instanceof MeshBasicMaterial
					|| material instanceof LineBasicMaterial) {
					currentColor = material.color.getHex();
					currentOpacity = material.opacity;
				}
				// Unsupported material type
				else {
					console.warn(`Update material is being called for an unsupported material type '${material.type}' `);
					return;
				}

				// Calculate the new color and opacity values
				const finalColor = newColor !== undefined ? parseInt(newColor.substring(1), 16) : currentColor;
				const finalOpacity = newOpacity !== undefined ? newOpacity : currentOpacity;

				// Create standardized material properties
				const meshMaterial: IMeshMaterial = {
					colour: '#' + finalColor.toString(16).padStart(6, '0'),
					opacity: finalOpacity,
				};

				// Use dedicated method to get/create the material
				iterObject.material = this.getMaterial(meshMaterial, hatched, material instanceof LineBasicMaterial);
			});
		});
	}


	private static standardMaterial (colour: string, opacity: number = 1): MeshStandardMaterial {
		return new MeshStandardMaterial({
			color: colour,
			transparent: (opacity ?? 1) < 1,
			opacity: opacity ?? 1
		});
	}

	private static hatchedMaterial (colour: string, opacity: number = 1): ShaderMaterial {
		// Applies a bar pattern in red on the colour, rotated in the direction of the viewer
		return new ShaderMaterial({
			uniforms: {
				baseColor: { value: new Color(colour) },
				bars: { value: 0 },
				opacity: { value: opacity ?? 1.0 },
			},
			vertexShader: `
				varying vec4 vPos;

				void main() {
					vPos = modelMatrix * vec4( position, 1.0 );
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
			fragmentShader: `
				uniform vec3 baseColor;
				uniform float bars;

				varying vec4 vPos;

				void main() {
					vec4 lineColor = vec4(1.0, 0.0, 0.0, 1.0);

					float interval = 0.15;
					float a = step(mod(vPos.x + vPos.y + vPos.z, interval) / (interval - 0.1), 0.1);

					if (a == 0.0) {
						gl_FragColor = vec4(baseColor, ${opacity});
					} else {
						gl_FragColor = lineColor;
					}
				}
			`,
			side: FrontSide,
			transparent: (opacity ?? 1) < 1
		});
	}

	public static lineMaterial (colour: string,
								 opacity: number = 1,
								 linewidth: number = 2): LineBasicMaterial {
		return new LineBasicMaterial({
			color: colour,
			transparent: opacity < 1,
			opacity: opacity,
			linewidth: linewidth
		});
	}

}
