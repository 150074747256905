import { Injectable } from '@angular/core';
import { updateRequestStatus } from '@ngneat/elf-requests';
import {map, take, tap} from 'rxjs/operators';
import { deleteEntities, setActiveId, updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';

import { ArticleService } from 'src/app/core/services/article.service';
import {CatalogItem, IItemPosition, Item} from 'src/app/shared/types';

import { itemsStore } from '../../../project/store/items/items.store';

import { catalogItemStore } from './catalog-items.store';
import { catalogItemsSelector } from './catalog-items.selectors';
import { CreateCatalogItemDto, UpdateCatalogItemDto } from './catalog-items.types';
import {ItemsRepository} from "~modules/project/store/items/items.repository";

@Injectable()
export class CatalogItemsRepository {
	public catalogItems$ = catalogItemsSelector.catalogItems$;
	public catalogItemsLoading$ = catalogItemsSelector.catalogItemsLoading$;
	public catalogItemLoading$ = catalogItemsSelector.catalogItemLoading$;
	public createCatalogItemLoading$ = catalogItemsSelector.createCatalogItemLoading$;
	public updateCatalogItemLoading$ = catalogItemsSelector.updateCatalogItemLoading$;
	public activeCatalogItem$ = catalogItemsSelector.activeCatalogItem$;

	constructor(private readonly articleService: ArticleService) {}

	public getCatalogItems(accountId: string): Observable<CatalogItem[]> {
		catalogItemStore.update(updateRequestStatus('get-catalog-items', 'pending'));
		return this.articleService
			.getCatalogItems(accountId)
			.pipe(
				take(1),
				tap((items) => {
					catalogItemStore.update(
						upsertEntities(items),
						updateRequestStatus('get-catalog-items', 'success')
					)
				})
			)
	}

	public getCatalogItem(catalogItemId: string): Observable<CatalogItem> {
		catalogItemStore.update(updateRequestStatus('get-catalog-item', 'pending'));
		return this.articleService
			.getCatalogItem(catalogItemId)
			.pipe(
				take(1),
				tap((item) => {
					catalogItemStore.update(
						upsertEntities(item),
						setActiveId(catalogItemId),
						updateRequestStatus('get-catalog-item', 'success')
					)
				})
			)
	}

	public createCatalogItem(partId: string, itemId: string, value: CreateCatalogItemDto, accountId: string): Observable<CatalogItem> {
		catalogItemStore.update(updateRequestStatus('create-catalog-item', 'pending'));
		return this.articleService
			.createCatalogItem(partId, itemId, value, accountId)
			.pipe(
				take(1),
				tap((item) => {
					catalogItemStore.update(
						upsertEntities(item),
						updateRequestStatus('create-catalog-item', 'success')
					)
				})
			)
	}

	public updateCatalogItem(catalogItemId: string, values: UpdateCatalogItemDto): Observable<CatalogItem> {
		catalogItemStore.update(updateRequestStatus('update-catalog-item', 'pending'));
		return this.articleService
			.updateCatalogItem(catalogItemId, values)
			.pipe(
				take(1),
				tap((item) => {
					catalogItemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-catalog-item', 'success')
					)
				})
			)
	}

	public toggleFavourite(catalogItemId: string, accountId: string): Observable<CatalogItem> {
		catalogItemStore.update(updateRequestStatus('toggle-catalog-item-favourite', 'pending'));
		return this.articleService
			.toggleCatalogItemFavourite(catalogItemId, accountId)
			.pipe(
				take(1),
				tap((item) => {
					catalogItemStore.update(
						updateEntities(catalogItemId, { favourite: item.favourite }),
						updateRequestStatus('toggle-catalog-item-favourite', 'success')
					)
				})
			)
	}

	public deleteCatalogItem(catalogItemId: string, accountId?: string): Observable<void> {
		catalogItemStore.update(updateRequestStatus('delete-catalog-item', 'pending'));
		return this.articleService
			.deleteCatalogItem(catalogItemId, accountId)
			.pipe(
				take(1),
				tap((item) => {
					catalogItemStore.update(
						deleteEntities(catalogItemId),
						updateRequestStatus('delete-catalog-item', 'success')
					)
				})
			)
	}

	public addCatalogItemToPart(partId: string, catalogItemId: string): Observable<Item> {
		catalogItemStore.update(updateRequestStatus('add-catalog-item-to-part', 'pending'));
		// @ts-ignore  // Todo check later
		return this.articleService
			.addCatalogItemToPart(partId, catalogItemId)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap(([item, ItemPosition]: [Item, IItemPosition]) => {
					itemsStore.update(
						upsertEntities(item as Item)
					);

					catalogItemStore.update(
						updateRequestStatus('add-catalog-item-to-part', 'success')
					)
				})
			)
	}
}
