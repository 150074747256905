export const PANEL_TYPE_TRANSLATIONS = {
	CARCASE: 'Corpus',
	DIVIDER: 'Middenstijl',
	FIXED_SHELVE: 'Vaste legplank',
	FRONT: 'Fronten',
	SHELVES: 'Verstelbare legplank',
	BACK: 'Rug',
	FRAME: 'Frame',
	BASE: 'Sokkel'
}
