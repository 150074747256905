import { ItemOutline } from "~shared/types/outline.types";
import { validateFields } from "~shared/helpers";
import { ConThreeObject } from "~shared/types/three.types";
import { RenderedEntity } from "~shared/types/rendered-object.types";
import { ConnectionType, ItemType, PanelType } from "~shared/enums";

import { ICoordinates } from "./dimension.types";
import { Article } from "./article.types";
import { ArticleZone } from "./article-zone.types";
import { Panel } from "./panel.types";

export interface ItemConfigurationOptions {
	connectionType: ConnectionType;
	maximumEdgebanding: boolean;
	visibleConnections: boolean;
	connectionTypeOptions: string[];
}

export interface IItem {
	id: string;
	name?: string;
	partId?: string;
	itemType?: ItemType;
	outline?: ItemOutline;
	configurationOptions?: ItemConfigurationOptions;
	articles?: Article[]; // Only includes articles related to the frame and its children
	articleZones?: ArticleZone[];
	fillerZones?: ArticleZone[];
	materialMap?: Record<string, string[]>;
	fillerArticles?: Article[];
	description?: string;
}

export interface IItemPosition {
	id: string;
	partCoordinate?: ICoordinates;
	partRotation?: ICoordinates;
}

export class Item extends RenderedEntity implements IItem {
	id: string;
	name: string;
	partId: string;
	itemType: ItemType;
	// Entity
	articles: Article[] = [] // Only includes articles related to the frame and its children
	articleZones: ArticleZone[] = [];
	fillerZones: ArticleZone[] = [];
	fillerArticles: Article[] = [];
	// Rendered object
	renderedObjects: ConThreeObject[] = [];
	configurationOptions?: ItemConfigurationOptions;
	//
	outline?: ItemOutline;
	materialMap?: Record<string, string[]>;

	get allArticles(): Article[] {
		return [...this.fillerArticles, ...this.articles]
	}

	get allArticleZones(): ArticleZone[] {
		return [...this.articleZones, ...this.fillerZones]
	}

	get allPanels(): Panel[] {
		return this.allArticles.flatMap(article => article.panels ?? [])
	}

	get childRenderedEntities(): RenderedEntity[] {
		return [...this.allArticles, ...this.allArticleZones]
	}

	get hashCode() {
		return this.id;
	}

	get hasFrontPanels(): boolean {
		return this.allPanels.some(panel =>
			panel.panelType === PanelType.FRONT || panel.panelType === PanelType.DRAWER_FRONT
		);
	}

	constructor(data: Partial<Item>) {
		validateFields(data, [
			// {key: "articleZones", instanceType: ArticleZone},
			// {key: "fillerZones", instanceType: ArticleZone}
		]);
		super(data)
		this.articles = data.articles && data.articles.length ? data.articles : [];
		this.fillerArticles = data.fillerArticles && data.fillerArticles.length ? data.fillerArticles : [];
		this.articleZones = data.articleZones && data.articleZones.length ? data.articleZones : [];
		this.fillerZones = data.fillerZones && data.fillerZones.length ? data.fillerZones : [];
	}

	public setObjectGroups() {
		this._objectGroups = [
			...this.allArticles.flatMap(article => article.id ? (article as Article)?.objectGroups ?? [] : []),
			...this.allArticleZones.flatMap(zone => (zone as ArticleZone)?.objectGroups ?? [])
		];
	}

	public getArticleZone(id: string): ArticleZone | undefined {
		return this.allArticleZones.find(az => az.id === id);
	}

	public getArticle(id: string): Article | undefined {
		// This function accepts the article, panel and articlezone ids to retrieve the related article
		const findArticle = (articleId: string): Article | undefined =>
		{return this.articles.find(article => article.id === articleId)}
		const article = findArticle(id)
		if (article) return article

		const articleZone = this.allArticleZones.find(az => az.id === id);
		if (articleZone) return findArticle(articleZone.parentId)

		const panel = this.allPanels.find(panel => panel.id === id);
		if (panel) return findArticle(panel.parentId)
		return undefined
	}

	public getPanel(id: string): Panel | undefined {
		for (const article of this.allArticles) {
			if (article && article.panels) {
				for (const panel of article.panels) {
					if (panel.id === id) {
						return panel;
					}
				}
			}
		}
		return undefined; // No panel found
	}
}
