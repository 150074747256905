import { RenderedObjectTypeEnum } from "../enums";
import { validateFields } from "../helpers";

import { Polyhedron } from "./polyhedron.types";
import { Article } from "./article.types";
import { ConGroup, ConThreeObject } from "./three.types";
import { RenderedEntity } from "./rendered-object.types";


export class ArticleZone extends RenderedEntity {
	// Entity
	id: string;
	parentId: string;
	polyhedron: Polyhedron;
	isVisible: boolean;
	article? : Article // Only filled for fillerarticles (not included in item articles)
	outlineFaceIdx?: number;
	outlineFaceNormal?: string; // Only filled for filler article zones
	frontPanelSelectable: boolean;
	renderedObjects: ConThreeObject[] = []

	get childRenderedEntities() {
		return [];
	}

	get hashCode(){return this.id}

	constructor(data: Partial<ArticleZone>) {
		validateFields(data, [
			// {key: "polyhedron", instanceType: Polyhedron },
			// {key: "article", instanceType: Article}
		])
		super(data);
		this.article = data.article;
		this.polyhedron = data.polyhedron;
	}

	protected setObjectGroups() {
		this._objectGroups = []
		if (this.article) {
			this._objectGroups.push(
				...this.article.objectGroups
			)
		}
		const polyGroup = new ConGroup(RenderedObjectTypeEnum.ENTITY, this)
		this.polyhedron.faces.flatMap(
			face => face.renderedObjects).forEach(rObject => polyGroup.add(rObject))
		this._objectGroups.push(polyGroup)
	}
}
