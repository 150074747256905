import { createStore, withProps } from '@ngneat/elf';
import { stateHistory } from '@ngneat/elf-state-history';
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { IItemPosition } from "~shared/types";


export const itemPositionsStore = createStore(
	{ name: 'item-position' },
	withEntities<IItemPosition>(),
	withRequestsStatus<string>()
)

export const itemPositionStoreHistory= stateHistory(itemPositionsStore);
