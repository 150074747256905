
export enum Colour {
	WHITE = "WHITE",
	BLACK = "BLACK",
	GREY = "GREY",
	BROWN = "BROWN",
	DARK_BROWN = "DARK_BROWN",
	BEIGE = "BEIGE",
	GREEN = "GREEN",
	RED = "RED",
	OTHER = "OTHER",
}

export enum Finish {
	ALUMINIUM = 'ALUMINIUM',
	MESSING = 'MESSING',
	COPPER = 'COPPER',
	BRONZE = 'BRONZE',
	GOLD = 'GOLD',
	SILVER = 'SILVER',
	NICKEL = 'NICKEL',
	METAL = 'METAL',
	INOX = 'INOX',
	OTHER = 'OTHER',
}

