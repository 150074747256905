import { Item } from "~shared/types";

export const zeroPad = (num, places) => String(num).padStart(places, '0')

export const findUpdatedPanel = (item: Item, panel) => {
	const availablePanels = [
		...item.articles.reduce((acc, article) => [...acc, ...article?.panels || []], []),
		...item.fillerZones.reduce((acc, zone) => [...acc, ...zone.article?.panels || []], []),
	];

	const availableHardware = [
		...item.articles.reduce((acc, article) => [...acc, ...article?.hardware || []], []),
	];

	const availableArticles = [
		...item.articles,
		...item.fillerZones.reduce((acc, zone) => [...acc, zone.article], []),
	].filter((article) => !!article)

	// Try to find the updated panel
	const updatedPanel = [
		...(availablePanels || []),
		...(availableArticles || [])
	]?.find((x) => x.id === panel.id) || availableHardware?.find((x) => x.sku === panel.sku);

	if (!updatedPanel) {
		return
	}

	return {
		...updatedPanel,
		article: availableArticles.find(({ id }) => id === panel.article?.id),
	};
}
