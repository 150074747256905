import { Injectable } from '@angular/core';
import { setProp } from "@ngneat/elf";
import { updateRequestStatus } from "@ngneat/elf-requests";
import { map, take, tap } from "rxjs/operators";
import { getEntity, upsertEntities } from "@ngneat/elf-entities";

import { IItemPosition } from "~shared/types";
import { itemsStore } from "~modules/project/store/items/items.store";
import { appStore } from "~shared/store/app/app.store";
import { ArticleService } from "~core/services/article.service";
import { ItemsRepository } from "~modules/project/store/items/items.repository";

import { itemPositionsSelector } from './item-positions.selectors';
import { itemPositionsStore, itemPositionStoreHistory } from "./item-positions.store";


@Injectable()
export class ItemPositionsRepository {
	public itemPositions$ = itemPositionsSelector.itemPositions$;
	public hasFuture$ = itemPositionStoreHistory.hasFuture$;
	public hasPast$ = itemPositionStoreHistory.hasPast$;

	constructor(private readonly articleService: ArticleService) {
	}

	public getItemPosition(itemId: string): IItemPosition {
		const itemPosition = itemPositionsStore.query(getEntity(itemId))
		if (!itemPosition) {
			throw new Error(`Item with id ${itemPosition.id} not found in itemposition repository.`);
		}
		return itemPosition;
	}

	public updateItemPosition(itemPosition: IItemPosition): void {
		appStore.update(setProp("loading", true));
		itemPositionsStore.update(updateRequestStatus('update-item-positions', 'pending'))
		const item= itemsStore.query(getEntity(itemPosition.id))
		if (!item) {
			throw new Error(`Item with id ${itemPosition.id} not found in itemrepository.`);
		}

		this.articleService
			.updateItemCoordinates(item.partId, itemPosition)
			.pipe(
				take(1),
				map(itemDto => ItemsRepository.decomposeItemDTO(itemDto)),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemPositionsStore.update(
						updateRequestStatus('update-item-coordinates', 'success'),
						upsertEntities(item)
					)
				}),
			)
	}

	public undo(): void {
		itemPositionStoreHistory.undo();
	}

	public redo(): void {
		itemPositionStoreHistory.redo();
	}

	public clearHistory(): void {
		itemPositionStoreHistory.clear();
	}
}
