import { Material, Mesh, Vector3, ShaderMaterial } from "three";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";

import { MaterialService } from "~shared/services/material.service";

import { RenderedObjectTypeEnum } from "../enums";

import { ConGroup, ConMesh, ConThreeObject } from "./three.types";

export interface SelectionMeta{
	intersectionPoint: Vector3;
}

export function equalsRenderedEntity<T extends RenderedEntity | null>(prev: T, current: T): boolean {
	// if (!prev || !current) return prev === current; // Handles null checks
	// return current.equals(prev);
	// return false;
	if (!prev && !current) return true;
	if (!prev || !current) return false;
	console.log('prev', prev);
	console.log('current', current);
	return prev?.id === current?.id;
}

export function equalsRenderedEntities<T extends RenderedEntity>(prev: T[], current: T[]): boolean {
	if (prev?.length !== current?.length) return false;
	const prevHashCodes = new Set(prev?.map(entity => entity.hashCode));
	const currentHashCodes = new Set(current?.map(entity => entity.hashCode));
	if (prevHashCodes.size !== currentHashCodes.size) return false;
	for (const code of prevHashCodes) {
		if (!currentHashCodes.has(code)) return false;
	}
	return true;
}

export abstract class RenderedEntity {
	public renderedObjects: ConThreeObject[] = []// all 3d objects specific to the entity (not in children)
	protected _objectGroups: ConGroup[] = undefined
	protected _objectGroup: ConGroup = undefined
	public defaultMaterial?: Material | LineMaterial

	// optional
	id?: string

	get objectGroup(): ConGroup {
		// single group with metadata identifier for the entity. Created to limit the number of groups
		if (!this._objectGroup) {
			this.setObjectGroup()
		}
		return this._objectGroup
	}

	get objectGroups(): ConGroup[] {
		// functionally grouped objects with identifiers for the entity
		if (!this._objectGroups) {
			this.setObjectGroups()
		}
		return this._objectGroups
	}

	abstract get hashCode(): string

	abstract get childRenderedEntities(): RenderedEntity[]

	get allRenderedObjects(): ConThreeObject[] {
		return [...this.renderedObjects, ...this.childRenderedObjects]
	}

	get childRenderedObjects(): ConThreeObject[] {
		return this.childRenderedEntities.flatMap(rEntity => rEntity.allRenderedObjects ?? [])
	}

	protected constructor(data: Partial<RenderedEntity>) {
		Object.assign(this, data)
	}

	public setObjectGroup() {
		console.log("RenderedObject: set object group")
		this._objectGroup = new ConGroup(RenderedObjectTypeEnum.ENTITY, this)

		this.objectGroups.forEach(group => group.children.forEach(groupChild => this._objectGroup.add(groupChild)))
	}

	protected setObjectGroups() {
		throw new Error("Not implemented")
	}

	public equals(other: RenderedEntity): boolean {
		return this.constructor == other.constructor && other.hashCode !== this.hashCode
	}

	public resetRenderedObjects() {
		if (!this.defaultMaterial) {
			console.warn(`Default material cannot be applied: no default material was set on type ${this.constructor.name}`)
			return
		}
		this.renderedObjects.forEach(rObject => {
			rObject.visible = true
			if ((rObject instanceof ConMesh) && (rObject.material !== this.defaultMaterial)) {
				const prevMaterial = rObject.material
				rObject.material = this.defaultMaterial
				MaterialService.cleanMaterial(prevMaterial)
			}
		})
		this.childRenderedEntities.forEach(rObject => rObject.resetRenderedObjects())
	}

	public applyMaterial(newMaterial: Material, applyToChildren: boolean = false) {
		if (!this.defaultMaterial) {
			const uniqueMaterials = this.getUniqueMaterials()
			if (uniqueMaterials.length != 1) {
				console.warn(`Cannot apply material on type ${this.constructor.name}: no default material was
				set and the length of unique materials was ${uniqueMaterials.length} instead of 1`)
				return
			}
			this.defaultMaterial = uniqueMaterials.pop()
		}
		this.renderedObjects.forEach(rObject => {
			rObject.traverse(child => {
				if (child instanceof Mesh) {
					if (Array.isArray(child.material)) {
						console.warn(`Skipping apply material on type ${this.constructor.name}: multiple materials found on the mesh`, child);
						return;
					}
					child.material = newMaterial;
				}})})
		if (applyToChildren) {this.childRenderedEntities.forEach(rObject => {rObject.applyMaterial(newMaterial, applyToChildren)})}
	}

	/* Function to find materials that are unique with respect to colour, opacity and type */
	public getUniqueMaterials() {
		const uniqueMaterials = new Map<string, Material>(); // Key → Material Map

		for (const object of this.renderedObjects) {
			object.traverse((child) => {
				if (child instanceof Mesh) {
					const materials = Array.isArray(child.material) ? child.material : [child.material];

					materials.forEach((mat) => {
						if (!mat) return;

						let colorHex = "nocolor";
						let opacityValue = mat.opacity ?? 1;

						// Special case for ShaderMaterial: Extract color from uniforms
						if (mat instanceof ShaderMaterial && mat.uniforms.baseColor) {
							colorHex = mat.uniforms.baseColor.value.getHexString();
							opacityValue = mat.uniforms.opacity?.value ?? opacityValue;
						} else if ("color" in mat) {
							colorHex = mat.color?.getHexString() || "nocolor";
						}

						// Create a unique key based on material type, color, and opacity
						const matKey = `${mat.constructor.name}-${colorHex}-${opacityValue}`;

						if (!uniqueMaterials.has(matKey)) {
							uniqueMaterials.set(matKey, mat);
						}
					});
				}
			});
		}
		return Array.from(uniqueMaterials.values()); // Convert Map to array
	}

	/* Returns a flat list of this entity and all child entities found recursively */
	public getAllRenderedEntities(): RenderedEntity[] {
		const flatten = (entity: RenderedEntity): RenderedEntity[] => {
			return [
				entity,
				...entity.childRenderedEntities.flatMap((child) => flatten(child)),
			];
		};
		return flatten(this);
	}

}
