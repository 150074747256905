import { select } from '@ngneat/elf';
import {selectActiveEntity, selectAllEntities} from '@ngneat/elf-entities';
import { selectRequestStatus } from '@ngneat/elf-requests';
import { map} from 'rxjs';

import { itemsStore } from './items.store';

// @ts-ignore
export const itemsSelector = {
	items$: itemsStore.pipe(selectAllEntities()),
	itemsLoading$: itemsStore.pipe(
		selectRequestStatus('items'),
		map((status) => status.value === 'pending')
	),
	deletePanelLoading$: itemsStore.pipe(
		selectRequestStatus('delete-panel'),
		map((status) => status.value === 'pending')
	),
	deleteArticleLoading$: itemsStore.pipe(
		selectRequestStatus('delete-article'),
		map((status) => status.value === 'pending')
	),
	recreateBackLoading$: itemsStore.pipe(
		selectRequestStatus('recreate-back'),
		map((status) => status.value === 'pending')
	),
	updateItemDimensionLoading$: itemsStore.pipe(
		selectRequestStatus('update-item-dimension'),
		map((status) => status.value === 'pending')
	),
	newItem$: itemsStore.pipe(select((state) => state.newItem)),
};
