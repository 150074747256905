import {BufferGeometry} from "three";

import { Face } from "~shared/types/face.types";
import { validateFields } from "~shared/helpers";
import { IMeshMaterial } from "~shared/types/material.types";
import {mergeBufferGeometries} from "~shared/builder/utils";

export class Polyhedron {
	// Entity
	height: number;
	width: number;
	depth: number;
	length?: number;
	thickness?: number;
	mesh?: IMeshMaterial;
	faces: Face[];
	// Geometry data
	#bufferGeometry: BufferGeometry;
	#bufferGeometries: Map<IMeshMaterial, BufferGeometry[]> = new Map<IMeshMaterial, BufferGeometry[]>() //separate geometry per material, only to be used if performance is issue

	get bufferGeometry(): BufferGeometry {
		if (this.#bufferGeometry) this.setBufferGeometry()
		return this.#bufferGeometry
	}

	get bufferGeometries() {
		// Use this in case you want to merge buffergeometries at a higher level
		if (!this.#bufferGeometries) this.setBufferGeometries()
		return this.#bufferGeometries
	}

	constructor(data : Partial<Polyhedron>
	) {
		validateFields(data, [{key: "faces", instanceType: Face, isArray: true}])
		Object.assign(this, data)
		this.faces = data?.faces;
	}

	private setBufferGeometry(): void {
		this.#bufferGeometry = mergeBufferGeometries( [...this.#bufferGeometries.values()].flat(1))
			|| new BufferGeometry()
	}

	private setBufferGeometries(): void{
		// Note that when faces need to be separately selectable, this cannot be used.
		for (const face of this.faces) {
			if (!this.#bufferGeometries.has(face.mesh)) this.#bufferGeometries.set(face.mesh, [])
			this.#bufferGeometries.get(face.mesh)!.push(...face.bufferGeometries);
		}
	}

}





