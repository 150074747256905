import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, filter, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { AuthService } from '~core/services/auth.service';
import { BoardsRepository } from '~modules/board/store/boards/boards.repository';
import { CustomerBoard, EnrichedBoard, FinishedBoard, RawBoard } from '~shared/types';
import {ProjectsRepository} from "~modules/project/store/projects/projects.repository";


@Component({
	templateUrl: "./board-selection.modal.html"
})
export class BoardSelectionModalComponent implements OnInit {
	public boards$: Observable<CustomerBoard[]>;
	public getCustomerBoardsLoading$: Observable<boolean>;
	public getCatalogBoardLoading$: Observable<boolean>;
	public activeBoard$: Observable<CustomerBoard>;
	public selectedBoards$: BehaviorSubject<(EnrichedBoard | FinishedBoard | RawBoard)[]> = new BehaviorSubject([])
	public selectedBoardLabels = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { selectedBoardLabels: string[]; icon?: string },
		public dialogRef: MatDialogRef<BoardSelectionModalComponent>,

		private readonly boardRepository: BoardsRepository,
		private readonly projectsRepository: ProjectsRepository,
		private readonly authService: AuthService,
	) {}

	public ngOnInit(): void {
		this.selectedBoardLabels = this.data.selectedBoardLabels;

		this.boards$ = this.boardRepository.boards$
			.pipe(
				tap((boards) => {
					this.selectedBoards$.next(this.data.selectedBoardLabels.map((boardSku) => boards.find((board) => board.sku === boardSku)))
				}),
				map((boards) => boards.filter((board) => {
					if (this.data.selectedBoardLabels.length > 1) {
						return true;
					}

					return board.id !== this.data.selectedBoardLabels[0]
				}))
			) as unknown as Observable<CustomerBoard[]>;

		this.getCustomerBoardsLoading$ = this.boardRepository.getCustomerBoardsLoading$;
		this.getCatalogBoardLoading$ = this.boardRepository.getCatalogBoardLoading$;
		this.activeBoard$ = this.boardRepository.activeItem$ as unknown as Observable<CustomerBoard>;

		combineLatest([this.authService.currentUser$, this.projectsRepository.activeProject$])
			.pipe(
				filter(([user, _]) => !!user),
				take(1),
				switchMap(([user, project]) => this.boardRepository.getCustomerBoards(user.account?.id || project.accountId)),
				switchMap(() => this.data.selectedBoardLabels?.[0] ? this.boardRepository.getCatalogBoard(this.data.selectedBoardLabels[0]) : of(null))
			)
			.subscribe()
	}

	public onSubmit(): void {
		this.boardRepository.boards$
			.pipe(take(1))
			.subscribe((boards) => this.dialogRef.close(boards.find((board) => board.sku === this.selectedBoardLabels[0])))
	}

	public selectBoard(externalSku: string): void {
		this.selectedBoardLabels = [externalSku];
		this.boardRepository.activateItem(externalSku);
	}

	public toggleActiveBoard(externalSku: string): void {
		this.boardRepository.activateItem(externalSku);
	}
}
