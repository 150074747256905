import { PanelType } from "~shared/enums";

export const CountryOptions = [
	{
		label: 'België',
		value: 'BE'
	},
	{
		label: 'Nederland',
		value: 'NL'
	},
	{
		label: 'Frankrijk',
		value: 'FR'
	},
	{
		label: 'Luxemburg',
		value: 'BELUX'
	},
	{
		label: 'Duitsland',
		value: 'DE'
	},
	{
		label: 'Verenigd Koninkrijk',
		value: 'UK'
	},
];

export const CompanyClassOptions = [
	{
		label: 'Besloten vennootschap',
		value: 'BV'
	},
	{
		label: 'Naamloze vennootschap',
		value: 'NV'
	},
	{
		label: 'Vennootschap onder firma',
		value: 'VOF'
	},
	{
		label: 'Eenmanszaak',
		value: 'EZ'
	},
	{
		label: 'Vereniging zonder winstoogmerk',
		value: 'VZW'
	},
];

export const ExternalUserRoleOptions = [
	{
		label: 'Administrator',
		value: 'ACCOUNT_ADMIN',
		icon: 'admin'
	},
	{
		label: 'Medewerker',
		value: 'ACCOUNT_USER',
		icon: 'worker'
	},
];

export const InternalUserRoleOptions = [
	{
		label: 'Sales',
		value: 'SALES',
		icon: 'worker'
	},
	{
		label: 'Designer',
		value: 'DESIGNER',
		icon: 'designer'
	},
	{
		label: 'Administrator',
		value: 'ADMIN',
		icon: 'admin'
	},
];

export const TransparentPanelTypes = [
	PanelType.TOP,
	PanelType.BOTTOM,
	PanelType.SIDELEFT,
	PanelType.SIDERIGHT,
	PanelType.BACK,
	PanelType.FILLER,
	PanelType.FILLER_SUPPORT,
	PanelType.FRONT,
	PanelType.DOOR,
	PanelType.DRAWER_FRONT
]
