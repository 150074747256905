import { createStore, withProps } from '@ngneat/elf';

import { ConfiguratorMode} from "~shared/enums";

import { ConfiguratorState } from './configurator.types';

export const configuratorStore = createStore(
	{ name: 'editor' },
	withProps<ConfiguratorState>({
		// Configurator
		configuratorMode: ConfiguratorMode.PROJECT,
		fullscreen: false,
		frontsVisible: false,
		activeConfigurationPanel: null,
		// Selection
		selectedEntities: [], // For panels/articlezones etc
		selectionMode: null,
		isolatedPanel: null, // Double click on panel
		panelsTransparent: false,
	})
)
