

export enum FaceNormal {
	FRONT = 'F',
	RIGHT = 'R',
	TOP = 'T',
	LEFT = 'L',
	BOTTOM = 'B',
	LEFT_TOP = 'L_T',
	RIGHT_TOP = 'R_T'
}
