import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { pick } from 'ramda';
import { filter, map, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import {
	FinishedBoard,
	Item,
	Part,
	Project,
	ProjectTree,
	RawBoard,
} from '~shared/types';
import {
	Button,
	StandardDialogComponent,
} from '~shared/components/standard-dialog/standard-dialog.component';
import { BoardSelectionModalComponent } from '~modules/configurator/modals/board-selection/board-selection.modal';
import { ItemsRepository } from '~modules/project/store/items/items.repository';

import { PANEL_TYPE_TRANSLATIONS } from './project-navigator.const';
import { PartsRepository } from '~modules/project/store/parts/parts.repository';
import { ConfiguratorRepository } from '~modules/configurator/store/configurator/configurator.repository';
import { ConfiguratorMode } from '~shared/enums';


@Component({
	selector: 'app-project-navigator',
	templateUrl: './project-navigator.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectNavigatorComponent implements OnInit, OnDestroy, OnChanges {
	public showNewCabinetGroupSelection: boolean = false;
	public openBoardPanels: Record<string, boolean> = {}
	public openPartBoardPanel: string;
	public boardPanels: Record<string, any>;
	public editingPart: string;
	public editingPartControl = new FormControl('');
	public editingItem: string;
	public editingItemControl = new FormControl('');
	public items$: Observable<any[]>;
	public items: any[];
	public itemSelectedInEditor: Item;
	public itemsLoading$: Observable<boolean>;
	public panelGroupTranslations = PANEL_TYPE_TRANSLATIONS;
	public selectedItem$: Observable<Item>;
	private componentDestroyed$: Subject<boolean> = new Subject();
	openParts = new Set<string>();

	@Input() project: ProjectTree;
	// @Input() selectedItem: Item;
	selectedItem: Item;
	@Input() selectedPart: Part;
	@Input() showNewCabinetSelection: Record<string, boolean>;
	@Input() configuratorMode: ConfiguratorMode;

	// @Output() selectItem = new EventEmitter();
	@Output() selectPart = new EventEmitter();
	@Output() createItem = new EventEmitter();
	@Output() setNewCabinetSelection = new EventEmitter();
	@Output() createPart = new EventEmitter();
	@Output() returnToMainView = new EventEmitter();

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly partsRepository: PartsRepository,
		private readonly configuratorRepository: ConfiguratorRepository,
		private readonly route: ActivatedRoute,
		private readonly dialog: MatDialog,
		private readonly router: Router ) {}

	public ngOnInit(): void {
		this.items$ = this.itemsRepository.items$
			.pipe(
				takeUntil(this.componentDestroyed$),
				// map((items: Item[]) => items.map((item) => ({
				// 	...item,
				// 	parsedMaterialMap: this.aggregateMaterialMap(item?.materialMap || {})
				// })))
			);

		this.itemsRepository.items$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((items) => items && !!items.length)
			).subscribe((items) => {
		     	this.items = items;
			// console.log('ALL_I', items)
				this.boardPanels = items
					// .map((item) => this.aggregateMaterialMap(item?.materialMap || {}))
					// .reduce((acc, materialMap) => {
					// 	return {
					// 		...acc,
					// 		...Object.keys(materialMap).reduce((materialAcc, key) => ({
					// 			...materialAcc,
					// 			[key]: acc[key] ? {
					// 				items:  Array.from(new Set([...acc[key].items, ...materialMap[key].items])),
					// 				sku: `${acc[key].sku}, ${materialMap[key].sku}`
					// 			} : materialMap[key]
					// 		}), {})
					// 	};
					// });
			})

		this.itemsLoading$ = this.itemsRepository.itemsLoading$;
		this.selectedItem$ = this.configuratorRepository.selectedItem$;
		this.selectedItem$.pipe(
			takeUntil(this.componentDestroyed$)).subscribe(item => {
			console.log('PN', item)
			this.selectedItem = item;
		})
	}

	ngOnChanges(changes: SimpleChanges) {
		// console.log('part_pn', this.selectedPart);
		// console.log('item_pn', this.selectedItem);
		// console.log('mode_pn', this.designerMode);
		this.itemSelectedInEditor = this.configuratorMode === ConfiguratorMode.PART ? this.selectedItem : null;
		if (changes['selectedPart'] && this.selectedPart?.id) {
			if (!this.openParts.has(this.selectedPart.id)) {
				this.openParts.add(this.selectedPart.id);
			}
			const prevPart = changes['selectedPart'].previousValue;
			if (prevPart) {
				if (this.openParts.has(prevPart.id)) {
					this.openParts.delete(prevPart.id);
				}
			}
		}
	}

	public handleOpenPartBoards(e: Event, part: Part): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		if (this.openPartBoardPanel === part.id) {
			this.openParts.delete(part.id);
			return this.openPartBoardPanel = null;
		}

		this.openPartBoardPanel = part.id;
		if (!this.openParts.has(part.id)) {
			this.openParts.add(part.id);
		}

		if (this.selectedItem && this.selectedItem.partId !== part.id || this.selectedPart && this.selectedPart.id !== part.id) {
			// this.handlePartSelect(part);
		}
	}

	public handleOpenPartMaterial(e: Event, partId: string, panelGroup: string, selectedBoardLabels: string[], singleMaterialUpdate = false): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		const dialogRef = this.dialog.open(BoardSelectionModalComponent, {
			data: { selectedBoardLabels }
		});

		dialogRef
			.afterClosed()
			.subscribe((action: RawBoard | FinishedBoard) => {
				if (!action) {
					return
				}

				this.itemsRepository.updatePartPanelGroupBoard(partId, panelGroup, {
					...pick(['sku', 'thickness', 'boardDimension'])(action),
					// ...(action.grain && { grain: action.grain })
				}, singleMaterialUpdate ? selectedBoardLabels[0] : null)
					.pipe(take(1))
					.subscribe();
			});
	}

	public handleOpenMaterial(e: Event, partId: string, itemId: string, panelGroup: string, selectedBoardLabels: string[], singleMaterialUpdate = false): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		const dialogRef = this.dialog.open(BoardSelectionModalComponent, {
			data: { selectedBoardLabels }
		});

		dialogRef
			.afterClosed()
			.subscribe((action: RawBoard | FinishedBoard) => {
				if (!action) {
					return
				}

				this.itemsRepository.updatePanelGroupBoard(partId, itemId, panelGroup, {
					...pick(['sku', 'thickness', 'boardDimension'])(action),
					// ...(action.grain && { grain: action.grain })
				}, singleMaterialUpdate ? selectedBoardLabels[0] : null)
					.pipe(take(1))
					.subscribe();
			});
	}

	public handleOpenBoards(e: Event, itemId: string): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.openBoardPanels[itemId] = !this.openBoardPanels[itemId] || false
	}

	public handleOpenItemCustomisations(e: Event, item: Item, part: Part): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.handleItemSelect(item);
		this.configuratorRepository.setActiveConfigurationPanel('ITEM')
	}

	public handleOpenPartCustomisations(e: Event, part: Part): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.handlePartSelect(part);
		this.configuratorRepository.setActiveConfigurationPanel('PART')
	}

	public async deletePart(part: Part, project: Project, e: Event): Promise<void> {
		e.preventDefault();
		e.stopImmediatePropagation();

		const dialogRef = this.dialog.open(StandardDialogComponent, {
			data: {
				title: 'Verwijderen kast',
				body: `Bent u zeker dat u kast "${part.name}" wil verwijderen uit project "${project.name}"?`,
				buttons: [new Button('Annuleren'), new Button('OK', 'DELETE', 'primary')],
				icon: 'trash',
				type: 'danger'
			},
		});

		dialogRef
			.afterClosed()
			.subscribe((action) => {
				if (action !== 'DELETE') {
					return
				}

				this.partsRepository.deletePart(part.id);
				this.configuratorRepository.setActiveConfigurationPanel(null);

				if (this.selectedPart?.id === part.id || this.selectedItem?.partId === part.id) {
					this.itemsRepository.clearItems();
				}
			});
	}

	// Asks if item can be deleted, if yes, delete item
	// After delete navigates to projectDetails (Emitter)
	public deleteItem(item: Item, part: Part, project: Project, e: Event): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		const dialogRef = this.dialog.open(StandardDialogComponent, {
			data: {
				title: 'Verwijderen kast',
				body: `Bent u zeker dat u kastelement "${item.name}" in groep "${part.name}" wil verwijderen uit project "${project.name}"?`,
				buttons: [new Button('Annuleren'), new Button('OK', 'DELETE', 'primary')],
				icon: 'trash',
				type: 'danger'
			},
		});

		dialogRef
			.afterClosed()
			.subscribe((action) => {
				if (action !== 'DELETE') {
					return
				}

				this.itemsRepository.deleteItem(part.id, item.id)
					.pipe(take(1))
					.subscribe(() =>
						this.configuratorRepository.setActiveConfigurationPanel(null));
			});
	}

	public duplicateItem(item: Item, part: Part, e: Event) {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.itemsRepository.duplicateItem(part.id, item.id)
			.pipe(take(1))
			.subscribe(() =>
				this.configuratorRepository.setActiveConfigurationPanel(null))
	}

	public saveItem(item: Item, part: Part, e: Event) {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.router.navigate(['/', 'projects', this.project.id, 'catalog', 'create'], {
			queryParams: {
				item: item.id,
				part: part.id,
			}
		})
	}

	public materialTrackBy(index, user) {
		return index;
	}

	public aggregateMaterialMap(materialMap: Record<string, string[]>): Record<string, Record<string, any>> {
		return Object.keys(materialMap).reduce((acc, groupKey: string) => {
			if (acc[groupKey]) {
				return {
					...acc,
					[groupKey]: {
						sku: `${acc[groupKey].sku}, ${materialMap[groupKey].join(', ')}`,
						items:  Array.from(new Set([...acc[groupKey].sku, ...materialMap[groupKey]]))
					}
				}
			}

			return {
				...acc,
				[groupKey]: {
					sku: materialMap[groupKey].join(', '),
					items: materialMap[groupKey]
				}
			}
		}, {})
	}

	public duplicatePart(part: Part, e: Event) {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.partsRepository.duplicatePart(part.id)
			.pipe(take(1))
			.subscribe()
	}

	public editPart(part: Part, e: Event) {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.editingPartControl.setValue(part.name);
		this.editingPart = part.id;
	}

	public editItem(item: Item, e: Event) {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.editingItemControl.setValue(item.name);
		this.editingItem = item.id;
	}

	public submitPartName(project: Project, part: Part, event: Event): void {
		event.preventDefault();
		event.stopImmediatePropagation();

		if (this.editingPartControl.value) {
			this.partsRepository.updatePart(part.id, {
				name: this.editingPartControl.value,
				projectId: project.id
			})
				.pipe(take(1))
				.subscribe(() => this.editingPart = null);
		}
	}

	public exitEditPartState(event: Event): void {
		event.preventDefault();
		event.stopImmediatePropagation();

		this.editingItemControl.setValue(null);
		this.editingPart = null;
	}

	public submitItemName(part: Part, item: Item, event: Event): void {
		event.preventDefault();
		event.stopImmediatePropagation();

		if (this.editingItemControl.value) {
			this.itemsRepository.updateItemName(item.id, part.id, this.editingItemControl.value)
				.pipe(take(1))
				.subscribe(() => this.editingItem = null);
		}
	}

	public exitEditItemState(event: Event): void {
		event.preventDefault();
		event.stopImmediatePropagation();

		this.editingItem = null;
	}

	public handleItemSelect(item: Item) {
		// this.router.navigate(['/', 'projects', this.project.id, 'editor']).then(() => {
		// 	this.configuratorRepository.setActiveConfigurationPanel(null);
			// this.configuratorRepository.setSelectedEntities([item])
			this.selectItem(item);
		// });
	}


	// Show Selected Item Mode
	public selectItem(item: Item): void {
		// this.configuratorRepository.setDesignerMode(ConfiguratorMode.ITEM);

		// this.configuratorRepository.setIsolatedItem(null);
		// this.configuratorRepository.setPanelsTransparent(false);
		//
		this.configuratorRepository.setSelectedEntities([item]);
		if (item) {
			return this.updateQueryParameters({
				object: null,
				item: item?.id || null,
				part: item?.partId || null,
			});
		}
		// this.itemsRepository.activateItem(item.id);
	}

    // test
	private updateQueryParameters = (params: Record<string, string | null | undefined>): void => {
		this.router.navigate([], {
			replaceUrl: true,
			relativeTo: this.route,
			queryParamsHandling: 'merge',
			queryParams: {
				...this.route.snapshot.queryParams,
				...params
			},
		});
	};


	public handlePartSelect(part: Part) {
		this.router.navigate(['/', 'projects', this.project.id, 'editor']).then(() =>
		{
			this.configuratorRepository.setActiveConfigurationPanel(null);
			this.selectPart.emit(part);
		});

		if (this.selectedItem?.partId === part.id) {
			return;
		}

		this.itemsRepository.getItems([part.id])
			.pipe(take(1))
			.subscribe()
	}

	public handleTogglePart(partId: string, e?: Event): void {
		e?.preventDefault()
		e?.stopImmediatePropagation();
		if (this.openParts.has(partId)) {
			this.openParts.delete(partId);
		} else {
			this.openParts.add(partId);
		}
	}

	public openCabinetSelection(partId: string): void {
		this.setNewCabinetSelection.emit(partId);
	}

	public openCabinetGroupSelection(): void {
		if (this.project.parts.length === 0) {
			this.createPart.emit();
		} else {
			this.showNewCabinetGroupSelection = !this.showNewCabinetGroupSelection;
		}
	}

	public clickCreateItem(part: Part): void {
		this.configuratorRepository.setActiveConfigurationPanel(null);
		this.selectPart.emit(part);
		// this.togglePart.emit(part.id);
		this.createItem.emit(part);
		this.router.navigate(['/', 'projects', this.project.id, 'editor'], {
			queryParams: {
				...this.route.snapshot.queryParams,
			}
		});
	}

	public clickAddItemFromCatalog(part: Part): void {
		this.router.navigate(['/', 'projects', this.project.id, 'catalog'], {
			queryParams: {
				part: part.id
			}
		})
	}

	public getItemsByPartId(partId: string) {
		const items = this.items?.filter(item => item.partId === partId);
		console.log('getItemsByPartId', items)
		return items;
	}

	public clickCreatePart(): void {
		this.showNewCabinetGroupSelection = false;
		this.createPart.emit();
	}


	isItemsListOpen(partId: string): boolean {
		return !!this.openParts.has(partId);
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
