

export enum PanelType {
	TOP = 'TOP',
	SIDELEFT = 'SIDELEFT',
	SIDERIGHT = 'SIDERIGHT',
	BOTTOM = 'BOTTOM',
	BACK = 'BACK',
	FRAME = 'FRAME',
	FILLER = 'FILLER',
	GENERIC = 'GENERIC',
	DIVIDER = 'DIVIDER',
	BASE_FRONT = 'BASE_FRONT',
	BASE_BACK = 'BASE_BACK',
	DOOR = 'DOOR',
	SHELF = 'SHELVE',
	FILLER_SUPPORT = 'FILLER_SUPPORT',
	FILLER_FRONT = 'FILLER_FRONT',
	FILLER_RECESSED = 'FILLER_RECESSED',
	FILLER_PANEL = 'FILLER_PANEL',
	FRONT = 'FRONT',
	DRAWER_FRONT = 'DRAWER_FRONT',
}

export enum BackPanelType {
	MOUNTED = "MOUNTED",
	FLUSH = "FLUSH",
	RECESSED = "RECESSED"
}

export enum DividerType {
	LOGICAL = 'LOGICAL',
	PANEL = 'PANEL',
}

export enum PanelGroup {
	FRAME = "FRAME",
	BACK = "BACK",
	SHELVES = "SHELVES",
	FRONT = "FRONT",
	DIVIDER = "DIVIDER",
	BASE = "BASE",
	FIXED_SHELVE = "FIXED_SHELVE"
}
