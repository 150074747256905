import {
	ArticleType,
	BaseType,
	Colour,
	ConstructionType,
	DrawerAccess,
	DrawerDepth,
	DrawerFrameMaterial,
	DrawerFrontType,
	DrawerHeight,
	DrawerWeightClass,
	FaceNormal,
	FiberCover,
	FillerType,
	RenderedObjectTypeEnum,
	SiphonSpaceWidth
} from "~shared/enums";
import { Polyhedron } from "~shared/types/polyhedron.types";
import { Finish } from "~shared/enums/colour.enum";
import { validateFields } from "~shared/helpers";
import { IShelves } from "~shared/types/shelves.types";
import { IDivider } from "~shared/types/divider.types";
import { ConGroup } from "~shared/types/three.types";
import { RenderedEntity } from "~shared/types/rendered-object.types";
import { HardwareInterface } from "~shared/types/hardware.types";

import { IParameterSet } from "./parameter.types";
import { IConstraint } from "./constraint.types";
import { Panel } from "./panel.types";
import { ILinearDivision } from "./linear-division";


export interface IConstructionTypeLocation {
	topConstructionType?: ConstructionType;
	topConstructionTypeOptions?: ConstructionType[];
	bottomConstructionType?: ConstructionType;
	bottomConstructionTypeOptions?: ConstructionType[];
	leftConstructionType?: ConstructionType;
	leftConstructionTypeOptions?: ConstructionType[];
	rightConstructionType?: ConstructionType;
	rightConstructionTypeOptions?: ConstructionType[];
}

export interface ISiphonSpace {
	width: SiphonSpaceWidth;
	offsetLeft: number;
}

export class Article extends RenderedEntity{
	// Entity
	id: string;
	parentId: string;
	removable?: boolean;
	articleType?: ArticleType;
	parameterSet?: IParameterSet;
	constraint?: IConstraint;
	panels?: Panel[] = [];
	shelves?: IShelves;
	divider?: IDivider;
	polyhedra?: Polyhedron[] = [];
	lineBoring?: boolean;
	adjustmentLevels?: number;
	fillerType?: FillerType;
	outlineFaceIdx?: number;
	outlineFaceNormal?: FaceNormal;
	base?: Article;
	baseType?: BaseType;
	construction?: IConstructionTypeLocation;
	hardware?: HardwareInterface[] = [];
	frontTypeOptions?: DrawerFrontType[] = [];
	heightOptions?: DrawerHeight[] = [];
	depthOptions?: DrawerDepth[] = [];
	weightClassOptions?: DrawerWeightClass[];
	stackHeight?: number;
	parentArticleId?: string;
	colour?: Colour|Finish;
	frontType?: DrawerFrontType;
	height?: DrawerHeight;
	depth?: DrawerDepth;
	drawerAccess?: DrawerAccess;
	drawerWeightClass?: DrawerWeightClass;
	frameMaterial?: DrawerFrameMaterial;
	matColour?: FiberCover;
	siphonSpace?: ISiphonSpace;
	drawerCardinality?: number;
	frameColour?: Colour;
	tipOn?: boolean;
	linearDivision?: ILinearDivision;

	get childRenderedEntities() {
		return [...this.panels, ...this.hardware]
	}

	get hashCode(){return this.id}

	constructor(
		data: Partial<Article>
	) {

		validateFields(data, [
			{key: "polyhedra", instanceType: Polyhedron, isArray: true},
			{key: "panels", instanceType: Panel, isArray: true},
			// {key: "base", instanceType: Article}
		] );

		super(data)
		this.panels = data.panels;
		this.polyhedra = data.polyhedra;
		this.base = data.base;
		this.hardware = data.hardware;

	}

	public setObjectGroups() {
		this._objectGroups = [];

		// Add hardware objects if hardware exists
		if (this.hardware.length) {
			const articleGroup = new ConGroup(RenderedObjectTypeEnum.ENTITY, this);
			this.hardware.forEach(hw => {
				hw.renderedObjects.forEach(hwObject => articleGroup.add(hwObject));
			});
			this._objectGroups.push(articleGroup);
		}

		// Add objectGroups from panels, polyhedra, and base (if it exists)
		const panelGroups = this.panels.map(panel => panel.objectGroup); // a panel is a single entity
		const baseGroups = this.base ? this.base.objectGroups : []; // for the base we want to identify panels

		this._objectGroups.push(...panelGroups, ...baseGroups);

		// Add polyhedra group if polyhedra exist, article entity needs to be attached for identification
		if (this.polyhedra.length) {
			const polyhedraGroup = new ConGroup(RenderedObjectTypeEnum.ENTITY, this);
			this.polyhedra.flatMap(poly => poly.faces.flatMap(face => face.renderedObjects))
				.forEach(rObject => polyhedraGroup.add(rObject));
			this._objectGroups.push(polyhedraGroup);
		}
	}
}

