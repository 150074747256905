import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';

import { BoardsRoutingModule } from './boards-routing.module';
import { BoardsService } from './services/boards.service';
import { Pages } from './pages';
import { Repositories } from './store';
import { Modals } from './modals';

@NgModule({
	declarations: [Pages, Modals],
	imports: [SharedModule, CommonModule, BoardsRoutingModule],
	providers: [BoardsService, Repositories],
})
export class BoardsModule {}
