import { select } from '@ngneat/elf';

import { configuratorStore } from './configurator.store';

export const configuratorSelector = {
	// Configurator
	configuratorMode$: configuratorStore.pipe(select((state) => state.configuratorMode)),
	fullScreen$: configuratorStore.pipe(select((state) => state.fullscreen)),
	activeConfigurationComponent$: configuratorStore.pipe(select((state) => state.activeConfigurationPanel)),
	// Selection
	selectedEntities$: configuratorStore.pipe(select((state) => state.selectedEntities)),
	selectionMode$: configuratorStore.pipe(select((state) => state.selectionMode)),
	isolatedPanel: configuratorStore.pipe(select((state) => state.isolatedPanel)),
	//  Visibility
	panelsTransparent: configuratorStore.pipe(select((state) => state.panelsTransparent)),
	frontsVisible$: configuratorStore.pipe(select((state) => state.frontsVisible)),
};
