import { UserRole, UserType } from "~modules/auth/types/user.types";
import { Language } from "~shared/enums";

export class AccountUser {
	id: number; //this could also be email
	password?: string;
	firstname: string;
	lastname: string;
	email: string;
	telephonenumber: string;
	language: Language;
	userRole: UserRole;
	userType: UserType;
	registered: boolean;
}
