
export function validateFields<T extends object>(
	data: Partial<T>,
	fields: { key: keyof T; instanceType: new (...args: any[]) => any; isArray?: boolean }[]
): void {
	for (const { key, instanceType, isArray } of fields) {
		const value = data[key];

		if (value === undefined) {
			continue; // Skip validation if the field is not present in the data. This makes the validation optional.
		}

		if (isArray) {
			if (!Array.isArray(value)) {
				throw new TypeError(`${String(key)} must be an array`);
			}

			for (let i = 0; i < value.length; i++) {
				const item = value[i];
				if (!(item instanceof instanceType)) {
					throw new TypeError(`Element at index ${i} in ${String(key)} must be an instance of ${instanceType.name}`);
				}
			}
		} else {
			if (!(value instanceof instanceType)) {
				throw new TypeError(`${String(key)} must be an instance of ${instanceType.name}`);
			}
		}
	}
}
