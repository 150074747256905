import { addBaseUrlToUrls, commonEnvironment } from "./environment.common";

export const environment = {
	...commonEnvironment,
	production: false,
	baseUrl: 'https://dev-api.kastenman.be',
	cognito: {
		REGION: 'eu-central-1',
		USER_POOL_ID: 'eu-central-1_yM3dGdOkC',
		APP_CLIENT_ID: '2hqga22tfe1u7fubfec1dvffdk',
		IDENTITY_POOL_ID: 'eu-central-1:cb734c97-63ea-4b72-aff9-5ce27e769414',
	}
};

addBaseUrlToUrls(environment, commonEnvironment);


