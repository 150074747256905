import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, combineLatest, take, takeUntil } from 'rxjs';

import { AccountService } from '~core/services/account.service';
import { AccountType, DeliverableAddressType } from '~modules/auth/types/account.types';
import { BoardsRepository } from '~modules/board/store/boards/boards.repository';
import { CompanyClassOptions, CountryOptions } from '~shared/shared.const';
import { UserType } from '~modules/auth/types/user.types';

import { buildIndividualAccountForm, buildProfessionalAccountForm } from './create-account.const';

@Component({
	templateUrl: "./create-account.modal.html"
})
export class CreateAccountModalComponent implements OnInit, OnDestroy {
	public countryOptions = CountryOptions;
	public companyClassOptions = CompanyClassOptions;
	public loading$: Observable<boolean>;
	public accountOptions = [];
	public form: FormGroup;
	public accountOptionsLoading$: Subject<boolean> = new Subject();
	public accountTypeFormControl = new UntypedFormControl(AccountType.INDIVIDUAL, []);
	public deliveryAddressTypeFormControl = new UntypedFormControl(DeliverableAddressType.SAME, []);

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<CreateAccountModalComponent>,
		private readonly fb: FormBuilder,
		private readonly accountService: AccountService,
		private readonly toastr: ToastrService,
	) {}

	public ngOnInit(): void {
		this.form = buildIndividualAccountForm(this.fb, DeliverableAddressType.SAME, UserType.INTERNAL);
		combineLatest([this.accountTypeFormControl.valueChanges, this.deliveryAddressTypeFormControl.valueChanges])
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe(([accountType, deliveryAddressType]) => {
				const oldFormValues = this.form.value;

				if (accountType === AccountType.INDIVIDUAL) {
					this.form = buildIndividualAccountForm(this.fb, deliveryAddressType, UserType.EXTERNAL);
					this.form.patchValue(oldFormValues);
					return;
				}

				this.form = buildProfessionalAccountForm(this.fb, deliveryAddressType);
				this.form.patchValue(oldFormValues);
			});
	}

	public onSubmit(): void {
		this.form.markAllAsTouched();

		if (!this.form.valid) {
			return
		}

		this.accountService.createAccount({
			...this.form.value,
		})
			.pipe(take(1))
			.subscribe((createdAccount) => {
				this.dialogRef.close(createdAccount.id);
				this.toastr.success('Account aangemaakt')
			})
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
