export const DeliveryTimeOptions = [
	{
		label: 'Direct leverbaar',
		value: '0',
	},
	{
		label: '2 weken',
		value: '2',
	},
	{
		label: '4 weken',
		value: '4',
	},
	{
		label: '6 weken',
		value: '6',
	}
]

export const GrainOptions = [
	{
		label: 'Verticaal',
		value: 'VERTICAL',
	},
	{
		label: 'Horizontaal',
		value: 'HORIZONTAL',
	},
	{
		label: 'Geen',
		value: 'NONE',
	},
]
