import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { combineLatest, filter, first, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { FormControl } from '@angular/forms';

import { Part, Item, ProjectTree, CuttingList, CuttingListLine } from '~shared/types';
import { CuttingListsRepository } from '~modules/project/store/cutting-lists/cutting-lists.repository';
import { zeroPad } from '~shared/helpers';
import { CuttingListLinesRepository } from '~modules/project/store/cutting-list-lines/cutting-list-lines.repository';

@Component({
	selector: 'app-cutting-list-navigator',
	templateUrl: './cutting-list-navigator.component.html',
})
export class CuttingListNavigatorComponent implements OnInit, OnChanges {
	loading = false; // Show loading icon on top op side nav
	public showNewCabinetGroupSelection: boolean = false;
	public showNewCabinetSelection: Record<string, boolean> = {};
	public openBoardPanels: Record<string, boolean> = {}
	public editingCuttingList: string;
	public editingCuttingListNameControl = new FormControl('');
	public editingItem: string;
	public editingItemControl = new FormControl('');
	public cuttingLists$: Observable<CuttingList[]>;
	public activeCuttingList$: Observable<CuttingList>;
	public cuttingListsLoading$: Observable<boolean>;
	public cuttingListLines$: Observable<CuttingListLine[]>;

	materials = []; // All the sorts of materials

	@Input() project: ProjectTree;
	@Input() selectedItem: Item;
	@Input() selectedPart: Part;
	@Input() openPart: string;

	constructor(
		private readonly cuttingListRepository: CuttingListsRepository,
		private readonly cuttingListLinesRepository: CuttingListLinesRepository
	) {}

	public ngOnInit(): void {
		this.cuttingLists$ = this.cuttingListRepository.cuttingLists$;
		this.activeCuttingList$ = this.cuttingListRepository.activeCuttingList$;
		this.cuttingListsLoading$ = this.cuttingListRepository.cuttingListsLoading$;
		this.cuttingListLines$ = this.cuttingListLinesRepository.cuttingListLines$;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['project'] && this.project && this.project.id) {
			this.cuttingListRepository.getAll(this.project.id)
				.pipe(take(1))
				.subscribe();
		}
	}

	public handleCuttingListSelection(cuttingListId: string): void {
		this.cuttingListRepository.activateCuttingList(cuttingListId)
	}

	public editCuttingList(cuttingList: CuttingList, e: Event) {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.editingCuttingListNameControl.setValue(cuttingList.name);
		this.editingCuttingList = cuttingList.id;
	}

	public submitCuttingListName(projectId: string, cuttingList: CuttingList, event: Event): void {
		event.preventDefault();
		event.stopImmediatePropagation();

		if (!this.editingCuttingListNameControl.value) {
			return;
		}

		this.cuttingLists$
			.pipe(
				first(),
				switchMap((lines) => {
					if (lines?.[0]?.id === 'temp') {
						return this.cuttingListRepository.create(this.project.id, {
							name: `Zaaglijst 001`
						})
					}

					return of(cuttingList)
				}),
				take(1),
				switchMap((cl) => {
					return this.cuttingListRepository.update(projectId, cl.id, {
						name: this.editingCuttingListNameControl.value,
					})
				}),
				take(1),
			)
			.subscribe(() => this.editingCuttingList = null)
	}

	public handleDuplicate(projectId: string, cuttingListId: string): void {
		this.cuttingListRepository.duplicate(projectId, cuttingListId)
			.pipe(take(1))
			.subscribe()
	}

	public handleDelete(projectId: string, cuttingListId: string): void {
		this.cuttingListRepository.delete(projectId, cuttingListId)
			.pipe(take(1))
			.subscribe()
	}

	public exitEditCuttingListState(event: Event) {
		event.preventDefault();
		event.stopImmediatePropagation();

		this.editingCuttingList = null;
		this.editingCuttingListNameControl.setValue(null);
	}

	public clickCreateCuttingList(): void {
		this.cuttingLists$
			.pipe(
				take(1),
				switchMap((cuttingLists) => this.cuttingListRepository.create(this.project.id, {
					name: `Zaaglijst ${zeroPad(cuttingLists.length + 1, 3)}`
				}).pipe(take(1))))
			.subscribe()
	}
}
