import { AppRepository } from '../../../shared/store/app/app.repository';
import { PartsRepository } from './parts/parts.repository';
import { ConfiguratorRepository } from '~modules/configurator/store/configurator/configurator.repository';
import { CuttingListsRepository } from './cutting-lists/cutting-lists.repository';
import { CuttingListLinesRepository } from './cutting-list-lines/cutting-list-lines.repository';
import { CatalogItemsRepository } from '~modules/configurator/store/catalog-items/catalog-items.repository';
import {ItemsRepository} from "~modules/project/store/items/items.repository";
import {ProjectsRepository} from "~modules/project/store/projects/projects.repository";
import {itemPositionsStore} from "./item-positions/item-positions.store";
import {ItemPositionsRepository} from "~modules/project/store/item-positions/item-positions.repository";

export const Repositories = [
	PartsRepository,
	ConfiguratorRepository,
	CuttingListsRepository,
	CuttingListLinesRepository,
	CatalogItemsRepository,
	AppRepository,
	ItemsRepository,
	ProjectsRepository,
	ItemPositionsRepository
];
