import { OutlineType } from "../enums/outline-type.enum";

import { DimensionField, ItemDimension } from "./dimension.types";

export class Outline {
	label: string;
	outlineType?: OutlineType;
	name?: string;
	description?: string;
	dimensions?: DimensionField[];
	available?: boolean;
}

export class OutlineSet{
	label: string;
	frontOutline: Outline | string;
	sideOutline: Outline | string;
	topOutline: Outline | string;
}

export interface ItemOutline {
  baseIdx: number;
  dimension: ItemDimension;
  fillerIdxs: number[];
  frontOutline?: string;
  sideOutline?: string;
  topOutline?: string;
}
