import {FaceNormal} from "~shared/enums";


export interface IEdge {
	faceIndex: number;
	faceNormal: FaceNormal;
	edgeType: string;
	edgeband: boolean;
	length: string; // should be number perhaps?
}
