import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, map, Observable } from 'rxjs';

import { BoardsRepository } from '~modules/board/store/boards/boards.repository';
import { BoardType } from '~shared/enums';

import { CreateFinishedBoardForm, CreateRawBoardForm } from './board.types';
import { DeliveryTimeOptions, GrainOptions } from './board.const';

@Component({
	templateUrl: "./board.modal.html"
})
export class BoardModalComponent implements OnInit {
	public loading$: Observable<boolean>;
	public form: FormGroup<CreateFinishedBoardForm | CreateRawBoardForm>;
	public grainOptions = GrainOptions;
	public deliveryWeeksOptions = DeliveryTimeOptions;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<BoardModalComponent>,
		private readonly fb: FormBuilder,
		private readonly boardsRepository: BoardsRepository,
	) {}

	public ngOnInit(): void {
		this.loading$ = combineLatest([
			this.boardsRepository.createRawBoardLoading$,
			this.boardsRepository.createFinishedBoardLoading$,
			this.boardsRepository.updateRawBoardLoading$,
			this.boardsRepository.updateFinishedBoardLoading$,
		])
			.pipe(map(([createRawBoardLoading, createFinishedBoardLoading, updateRawBoardLoading, updateFinishedBoardLoading]) => {
				return createRawBoardLoading || createFinishedBoardLoading || updateRawBoardLoading || updateFinishedBoardLoading
			}))

		this.form = this.fb.group({
			edgeband: this.fb.group({
				label: [null as string, Validators.required],
				description: [null as string],
				manufacturerCode: [null as string, Validators.required],
				colour: [null as string, Validators.required],
				glueType: [null as string, Validators.required],
				width: [null as number, Validators.required],
				thickness: [null as number],
				edgebandType: [null as string, Validators.required],
				edgebandManufacturer: [null as string, Validators.required],
			}),
			isDefault: [false as boolean],
			description: [null as string],
			manufacturer: [null as string, Validators.required],
			supplier: [null as string, Validators.required],
			externalName: [null as string],
			externalSku: [null as string, Validators.required],
			imageUrl: [null as string],
			productUrl: [null as string],
			colour: [null as string, Validators.required],
			grain: [null as string],
			priceRange: [null as number, Validators.required],
			deliveryWeeks: [null as number, Validators.required],
			thickness: [null as string, Validators.required],
			boardDimension: this.fb.group({
				length: [null as number, Validators.required],
				width: [null as number, Validators.required],
			}),
			isVoid: [false, Validators.required],
			...(this.data.boardType === 'RAW' ? ({
				rawBoardType: [null as string, Validators.required],
				rawBoardAspects: [[] as string[]],
			}) : ({
				rawBoardType: [null as string, Validators.required],
				rawBoardAspects: [[] as string[]],
				overlayCategory: [null as string],
				overlayType: [null as string],
				overlayAspect: [null as string],
				doubleSided: [false],
			}))
		});

		if (this.data.board) {
			this.form.patchValue(this.data.board)
		}
	}

	public cancel(): void {
		this.dialogRef.close()
	}

	public handleSubmit(): void {
		this.form.markAllAsTouched();

		if (!this.form.valid) {
			return
		}

		if (this.data.action === 'CREATE') {
			this.handleCreate(this.form.value)
				.subscribe(() => {
					this.dialogRef.close()
				})
			return
		}

		if (this.data.action === 'UPDATE') {
			this.handleUpdate(this.form.value)
				.subscribe(() => {
					this.dialogRef.close()
				})
			return
		}

		this.dialogRef.close()
	}

	handleCreate(values): Observable<unknown> {
		if (this.data.board.boardType === BoardType.RAW) {
			return this.boardsRepository.createRawBoard(values)
		}

		if (this.data.board.boardType === BoardType.FINISHED) {
			return this.boardsRepository.createFinishedBoard(values)
		}
	}

	handleUpdate(values): Observable<unknown> {
		if (this.data.board.boardType === BoardType.RAW) {
			return this.boardsRepository.updateRawBoard(this.data.boardSku, values)
		}

		if (this.data.board.boardType === BoardType.FINISHED) {
			return this.boardsRepository.updateFinishedBoard(this.data.boardSku, values)
		}
	}
}
