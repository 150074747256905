import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, take } from 'rxjs';

import { AccountService } from '~core/services/account.service';
import { BoardsRepository } from '~modules/board/store/boards/boards.repository';
import { BoardType } from '~shared/enums';

@Component({
	templateUrl: "./create-customer-board.modal.html"
})
export class CreateCustomerBoardModalComponent implements OnInit {
	public loading$: Observable<boolean>;
	public accountOptions = [];
	public form: FormGroup;
	public accountOptionsLoading$: Subject<boolean> = new Subject();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<CreateCustomerBoardModalComponent>,
		private readonly fb: FormBuilder,
		private readonly accountService: AccountService,
		private readonly boardsRepository: BoardsRepository,
		private readonly toastr: ToastrService,
	) {}

	public ngOnInit(): void {
		this.form = this.fb.group({
			accountId: [null, Validators.required]
		});

		this.loading$ = this.boardsRepository.createCustomerBoardLoading$;
		this.accountOptionsLoading$.next(true);
		this.accountService.getAccounts()
			.pipe(take(1))
			.subscribe((accounts) => {
				this.accountOptionsLoading$.next(false);
				this.accountOptions = accounts.map((account) => ({
					label: account.name,
					value: account.id
				}));
			})
	}

	public handleSubmit(): void {
		this.form.markAllAsTouched();

		if (!this.form.valid) {
			return
		}

		this.boardsRepository.createCustomerBoards(this.form.value.accountId, this.data.boardSku)
			.pipe(take(1))
			.subscribe(() => {
				this.dialogRef.close();
				this.toastr.success('Board successvol toegewezen')
			})
	}
}
