import {
	AfterViewInit,
	Component,
	ElementRef, EventEmitter,
	Input,
	OnDestroy, Output,
	ViewChild
} from "@angular/core";

import { SceneManager } from "~shared/scenes/sceneManager";


@Component({
	selector: 'app-three-canvas',
	templateUrl: './three-canvas.component.html'
})
export class ThreeCanvasComponent implements AfterViewInit, OnDestroy {
	@ViewChild('container', { read: ElementRef }) containerRef: ElementRef;
	@Input() sceneManager: SceneManager;
	@Output() canvasReady = new EventEmitter();

	protected observer: ResizeObserver;
	ngAfterViewInit(): void {
		this.observer = new ResizeObserver(([entry]) => {
			const width = this.containerRef.nativeElement.clientWidth;
			const height = this.containerRef.nativeElement.clientHeight;

			if (width > 0 && height > 0) {
				this.sceneManager.resize(width, height);
			}
		});

		this.observer.observe(this.containerRef?.nativeElement);
		this.initialise();
	}

	private initialise() {
		const nativeElement = this.containerRef?.nativeElement
    	nativeElement.innerHTML = ''; // Clear any content in the block
		const { width, height } = nativeElement.getBoundingClientRect();
		this.sceneManager.resize(width, height);

		this.containerRef.nativeElement.appendChild(this.sceneManager.renderer.domElement);
		this.sceneManager.initialise();
		this.canvasReady.emit();

		this.sceneManager.animate();

	}

	ngOnDestroy(): void {
		this.sceneManager.cleanScene();
		this.observer.disconnect();
	}
}
