import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ConfiguratorRepository } from '~modules/configurator/store/configurator/configurator.repository';
import { ConfiguratorMode } from '~shared/enums';
import {Part, Project, Item, ProjectTree} from '~shared/types';

@Component({
	selector: 'app-navigation-panel',
	templateUrl: './navigation-panel.component.html'
})

export class NavigationPanelComponent implements OnInit {
	@Input() project: ProjectTree;
	@Input() items: Item[];
	@Input() parts: Part[];
	@Input() showNewCabinetSelection: Record<string, boolean>;
	@Input() selectedPart: Part;
	@Input() configuratorMode: ConfiguratorMode;

	@Output() createItem = new EventEmitter();
	@Output() createPart = new EventEmitter();
	@Output() setNewCabinetSelection = new EventEmitter();
	@Output() selectPart = new EventEmitter();
	@Output() returnToMainView = new EventEmitter();

	constructor(
		private readonly editorRepository: ConfiguratorRepository,
		private readonly router: Router
	) {}

	public ngOnInit(): void {
	}

	public onSelectPart(data: Part): void {
		this.selectPart.emit(data);
	}

	public onCreateItem(part: Part): void {
		this.createItem.emit(part);
	}

	public onSetNewCabinetSelection(partId: string): void {
		this.setNewCabinetSelection.emit(partId);
	}

	public onSetDesignerMode(designerMode: ConfiguratorMode): void {
		this.editorRepository.setDesignerMode(designerMode);

		switch (designerMode) {
		case ConfiguratorMode.CUTTING_LIST:
			this.router.navigate(['/', 'projects', this.project.id, 'cutting-lists']);
			break;

		default:
			this.router.navigate(['/', 'projects', this.project.id, 'editor']);
		}
	}

	public onCreatePart(): void {
		this.createPart.emit(null);
	}

	protected readonly ConfiguratorMode = ConfiguratorMode;
}
