import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {Observable, Subject, map, takeUntil, filter} from 'rxjs';

import { ConfiguratorRepository } from '~modules/configurator/store/configurator/configurator.repository';
import {Article} from "~shared/types";
import {ArticleType} from "~shared/enums";


@Component({
	selector: 'app-shared-construction-parameters',
	templateUrl: './shared-construction-parameters.component.html'
})
export class SharedConstructionParametersComponent implements OnInit, OnDestroy {
	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	public selectedArticle$: Observable<Article>;

	constructor (
		private readonly editorRepository: ConfiguratorRepository,
	) {}

	public ngOnInit(): void {
		this.selectedArticle$ = this.editorRepository.selectedArticle$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter(article => article.articleType === ArticleType.FILLER)
			);
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
