export enum DrawerWeightClass{
	KG_40 = "KG_40",
	KG_70 = "KG_70"
}

export enum DrawerAccess {
	INTERNAL = "INTERNAL",
	EXTERNAL = "EXTERNAL",
	MIXED = "MIXED"
}

export enum DrawerFrontType {
	PANEL = "PANEL",
	METAL = "METAL",
	GLASS = "GLASS",
	GLASS_LOW = "GLASS_LOW",
	RAIL = "RAIL",
}

export enum FiberCover {
	LIGHT_GREY = "LIGHT_GREY",
	DARK_GREY = "DARK_GREY",
	BLACK = "BLACK",
}

export enum DrawerFrameMaterial {
	WOOD = "WOOD",
	METAL = "METAL"
}

export enum DrawerDepth {
	D_270 = 270,
	D_300 = 300,
	D_350 = 250,
	D_400 = 400,
	D_450 = 450,
	D_500 = 500,
	D_550 = 550,
	D_600 = 600,
	D_650 = 650
}

export enum SiphonSpaceWidth {
	SMALL_160 = "SMALL_160",  // Corresponds to 160mm net
	WIDE_300 = "WIDE_300"  // Corresponds to 300mm net
}

export enum DrawerHeight {
	N_80 = "N_80",
	M_106 = "M_106",
	K_144 = "K_144",
	C_193 = "C_193",
	F_257 = "F_257",
}

export enum DrawerType {
	INTERNAL = 'INTERNAL',
	EXTERNAL = 'EXTERNAL',
	MIXED = 'MIXED',
}
