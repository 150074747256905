import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
	BehaviorSubject,
	combineLatest,
	filter,
	first,
	map,
	Observable,
	startWith,
	Subject,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs';
import Fuse from 'fuse.js';
import { MatDialog } from '@angular/material/dialog';
import { ShepherdService } from 'angular-shepherd';
import { offset } from '@floating-ui/dom';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '~core/services/auth.service';
import { BoardsRepository } from '~modules/board/store/boards/boards.repository';
import { BoardOverlayType, BoardType } from '~shared/enums';
import {
	EnrichedBoard,
	FinishedBoard,
	RawBoard,
} from '~shared/types';
import {
	ExternalUser,
	InternalUser,
	UserType,
} from '~modules/auth/types/user.types';
import { BoardModalComponent } from '../../modals/board/board.modal';
import { CreateCustomerBoardModalComponent } from '~modules/board/modals/create-customer-board/create-customer-board.modal';

import {
	boardTypeValues,
	boardOverlayTypeValues,
	titleCase,
} from './list.component.const';

@Component({
	templateUrl: './list.component.html',
})
export class ListPageComponent implements OnInit, OnDestroy, AfterViewInit {
	public boards$: Observable<(EnrichedBoard | FinishedBoard | RawBoard)[]>;
	public enrichedBoardsLoading$: Observable<boolean>;
	public getCatalogBoardLoading$: Observable<boolean>;
	public deleteCatalogBoardLoading$: Observable<boolean>;
	public activeBoard$: Observable<EnrichedBoard | FinishedBoard | RawBoard>;
	public activeBoardType$: BehaviorSubject<BoardType> = new BehaviorSubject(
		BoardType.FINISHED
	);
	public activeBoardOverlayType$: BehaviorSubject<BoardOverlayType> =
		new BehaviorSubject(BoardOverlayType.MELAMINE);
	public searchForm = new FormControl('');
	public filterForm = new FormGroup({
		overlayType: new FormControl(null),
		manufacturer: new FormControl(null),
		colour: new FormControl(null),
		mappedDimension: new FormControl(null),
		mappedThickness: new FormControl(null),
		rawBoardType: new FormControl(null),
	});
	public boardTypeValues = boardTypeValues(UserType.EXTERNAL);
	public boardOverlayTypeValues = boardOverlayTypeValues();
	public currentUser$: BehaviorSubject<InternalUser | ExternalUser>;
	public sortKey$: BehaviorSubject<string | undefined> = new BehaviorSubject(undefined)
	public sortDirection$: BehaviorSubject<string> = new BehaviorSubject('asc')
	public filterSets: Record<string, { label: string, value: string }[]> = {}

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly boardRepository: BoardsRepository,
		private readonly authService: AuthService,
		private readonly dialog: MatDialog,
		private readonly shepherdService: ShepherdService,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
	) {}

	public ngOnInit(): void {
		// this.dialog.open(BoardModalComponent)
		this.currentUser$ = this.authService.currentUser$;
		this.boards$ = combineLatest([
			this.searchForm.valueChanges.pipe(startWith(null)),
			this.boardRepository.boards$.pipe(startWith(null)),
			this.sortKey$,
			this.sortDirection$,
			this.filterForm.valueChanges.pipe(startWith(null)),
		]).pipe(
			map(([search, boards, sortKey, sortDirection, filterForm]) => {
				const boardsWithDimensions = boards.map((b) => ({
					...b,
					mappedDimension: this.parseDimensionVariants(b).dimension,
					mappedThickness: this.parseDimensionVariants(b).thickness
				}))

				return [search, boardsWithDimensions, sortKey, sortDirection, filterForm]
			}),
			map(([search, boards, sortKey, sortDirection, filterForm]: any) => {
				const boardsWithDimensions = boards.map((b) => ({
					...b,
					overlayType: b.overlayType === 'LAMINATE' ? 'HPL' : b.overlayType === 'CPLAMINATE' ? 'CPL' : titleCase(b.overlayType)
				}))

				return [search, boardsWithDimensions, sortKey, sortDirection, filterForm]
			}),
			tap(([_, boards]: any) => {
				// Collect unique values
				this.filterSets = {
					overlayTypes: [...new Set([...boards.map((b: EnrichedBoard) => b.overlayType)])].map((value) => ({ label: value, value })),
					manufacturers: [...new Set([...boards.map((b: EnrichedBoard) => b.manufacturer)])].map((value) => ({ label: value, value })),
					// externalSkus: new Set([...board.map((b: EnrichedBoard) => b.manufacturer)]).map((value) => ({ label: value, value })),
					colours: [...new Set([...boards.map((b: EnrichedBoard) => b.colour)])].map((value) => ({ label: value, value })),
					mappedDimensions: [...new Set([...boards.map((b: any) => b.mappedDimension)])].map((value) => ({ label: value, value })),
					rawBoardTypes: [...new Set([...boards.map((b: EnrichedBoard) => b.rawBoardType)])].map((value) => ({ label: value, value })),
					mappedThicknesses: [...new Set([...boards.flatMap((b: any) => b.mappedThickness.split(' | '))])].map((value) => ({ label: value, value }))
						.filter((value, index, self) =>
							index === self.findIndex((v) => v.label === value.label)
						),
				}
			}),
			map(([search, boards, sortKey, sortDirection, filterForm]: any) => {
				if (!filterForm) {
					return [search, boards, sortKey, sortDirection]
				}

				const filteredBoards = boards.filter((b) => {
					if (filterForm.overlayType && filterForm.overlayType !== b.overlayType) {
						return false
					}

					if (filterForm.manufacturer && filterForm.manufacturer !== b.manufacturer) {
						return false
					}

					if (filterForm.mappedDimension && filterForm.mappedDimension !== b.mappedDimension) {
						return false
					}

					if (filterForm.mappedThickness && !b.mappedThickness.split(' | ').includes(filterForm.mappedThickness)) {
						return false
					}

					if (filterForm.rawBoardType && filterForm.rawBoardType !== b.rawBoardType) {
						return false
					}

					return true;
				})

				return [search, filteredBoards, sortKey, sortDirection]
			}),
			map(([search, boards, sortKey, sortDirection]: any) => {
				if (sortKey) {
					const sortedBoards = boards
						.sort((a, b) => {
							if (typeof a[sortKey] === 'number') {
								if (sortDirection === 'asc') {
									return a[sortKey] - b[sortKey]
								}

								return b[sortKey] - a[sortKey]
							}

							if (sortDirection === 'asc') {
								return a[sortKey].toLowerCase().localeCompare(b[sortKey].toLowerCase());
							}

							return b[sortKey].toLowerCase().localeCompare(a[sortKey].toLowerCase());
						});

					return [search, sortedBoards, sortKey, sortDirection]
				}

				return [search, boards, sortKey, sortDirection]
			}),
			map(([search, boards]) => {
				if (!search) {
					return boards;
				}

				const fuse = new Fuse(boards, {
					keys: [
						'externalLabel',
						'externalName',
						'rawBoardType',
						'manufacturer',
						'description',
						'externalName',
						'supplier',
					],
					includeScore: true,
					shouldSort: false,
					threshold: 0.2,
				});

				return fuse.search(search).map((i) => i.item);
			})
		);

		this.enrichedBoardsLoading$ =
			this.boardRepository.enrichedBoardsLoading$;
		this.getCatalogBoardLoading$ =
			this.boardRepository.getCatalogBoardLoading$;
		this.deleteCatalogBoardLoading$ =
			this.boardRepository.deleteCatalogBoardLoading$;

		this.activeBoard$ = this.boardRepository
			.activeItem$ as Observable<EnrichedBoard>;

		this.authService.currentUser$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((user) => !!user),
				tap((user) => {
					if (user.userType === UserType.INTERNAL) {
						this.boardTypeValues = boardTypeValues(
							UserType.INTERNAL,
							user
						);
					}
				}),
				switchMap((user) =>
					combineLatest([
						this.activeBoardType$,
						this.activeBoardOverlayType$,
					]).pipe(
						map(([activeBoardType, activeBoardOverlayType]) => ({
							user,
							activeBoardType,
							activeBoardOverlayType,
						}))
					)
				),
				switchMap(
					({ user, activeBoardType, activeBoardOverlayType }) => {
						if (activeBoardType === BoardType.FAVOURITED) {
							return this.boardRepository.getCustomerBoards(
								user?.account?.id
							);
						}

						return this.boardRepository.getEnrichedBoards(
							user?.account?.id,
							activeBoardType,
							activeBoardType === BoardType.FINISHED &&
								activeBoardOverlayType
						);
					}
				)
			)
			.subscribe(() => setTimeout(() => this.startEditorTour()));
	}

	public toggleSort(sortKey: string): void {
		combineLatest([
			this.sortKey$,
			this.sortDirection$
		])
			.pipe(first())
			.subscribe(([currentSortKey, currentSortDirection]) => {
				if (currentSortKey !== sortKey) {
					// New sorting, overwrite
					this.sortKey$.next(sortKey)
					return this.sortDirection$.next('asc')
				}

				if (currentSortDirection === 'asc') {
					return this.sortDirection$.next('desc')
				}

				if (currentSortDirection === 'desc') {
					this.sortKey$.next(undefined)
				}
			})
	}

	public ngAfterViewInit() {
		this.shepherdService.modal = true;
		this.shepherdService.confirmCancel = false;
	}

	public startEditorTour(): void {
		if (!this.route.snapshot.queryParams.fromEditor) {
			return;
		}

		setTimeout(() => {
		}, 500)
		this.router.navigate(['.'], { relativeTo: this.route, queryParams: null, replaceUrl: true })
	}

	public toggleCustomerBoard(
		e: Event,
		boardSku: string,
		boardId: string,
		shouldAddToList?: boolean
	): void {
		e.stopPropagation();
		e.preventDefault();

		this.authService.currentUser$
			.pipe(
				take(1),
				switchMap((user) => {
					if (!user) {
						return;
					}

					if (shouldAddToList) {
						return this.boardRepository.createCustomerBoards(
							user.account.id,
							boardSku,
							boardId
						);
					}

					return this.boardRepository.deleteCustomerBoard(
						user.account.id,
						boardId,
						boardSku
					);
				}),
				take(1),
				switchMap(() => this.activeBoardType$.pipe(take(1)))
			)
			.subscribe((boardType) => {
				if (boardType !== BoardType.FAVOURITED) {
					return;
				}

				this.authService.currentUser$
					.pipe(take(1))
					.subscribe((user) => {
						return this.boardRepository
							.getCustomerBoards(user.account.id)
							.pipe(take(1))
							.subscribe();
					});
			});
	}

	public favouriteBoard(boardSku: string, favourite?: boolean): void {
		this.authService.currentUser$
			.pipe(
				take(1),
				switchMap((user) => {
					return this.boardRepository
						.favouriteCustomerBoard(user.account.id, boardSku)
						.pipe(take(1));
				})
			)
			.subscribe();
	}

	public parseDimensionVariants(
		board: EnrichedBoard | FinishedBoard | RawBoard
	) {
		if (!board.dimensionVariants) {
			return {
				thickness: board.dimension.thickness,
				dimension: `${board.dimension.length}x${board.dimension.width}`,
			};
		}

		const thicknesses = new Set(
			board.dimensionVariants.map((t) => t.dimension.thickness)
		);
		const dimensions = new Set(
			board.dimensionVariants.map(
				(t) => `${t.dimension.length}x${t.dimension.width}`
			)
		);

		return {
			thickness: [...thicknesses].join(' | '),
			dimension: [...dimensions].join(' | '),
		};

	}

	public selectBoard(id: string): void {
		this.boardRepository.activateItem(id);
	}

	public handleBoardType(boardType: BoardType): void {
		this.searchForm.setValue('')
		this.activeBoardType$.next(boardType);
	}

	public handleBoardOverlayType(overlayType: BoardOverlayType): void {
		this.filterForm.reset({})
		this.activeBoardOverlayType$.next(overlayType);
	}

	public handleCreateBoard(boardType: BoardType): void {
		this.dialog.open(BoardModalComponent, {
			data: { boardType, action: 'CREATE' },
		});
	}

	public handleEditBoard(e: Event, boardSku: string): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.boardRepository
			.getCatalogBoard(boardSku, false)
			.pipe(
				tap((board) => {
					return this.dialog.open(BoardModalComponent, {
						data: {
							board,
							boardType: board.boardType,
							boardSku,
							action: 'UPDATE',
						},
					});
				})
			)
			.subscribe();
	}

	public checkMouseOver(e: Event): void {
		this.shepherdService.addSteps([
			{
				advanceOn: { selector: '*', event: 'click' },
				text: `Kies eerst een account voor je gebruiker lijst probeert te wijzigen`,
				attachTo: {
					element: '#a-account-switcher',
					on: 'bottom',
				},
				floatingUIOptions: {
					middleware: [offset(10)],
				},
				id: 'account-selector',
				classes: 'shepherd-info'
			},
		]);
		this.shepherdService.start();
	}

	public handleDeleteBoard(e: Event, boardSku: string): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.boardRepository
			.deleteCatalogBoard(boardSku)
			.pipe(take(1))
			.subscribe();
	}

	public handleCreateCustomerBoard(e: Event, boardSku: string): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.dialog.open(CreateCustomerBoardModalComponent, {
			data: { boardSku },
		});
	}

	public ngOnDestroy(): void {
		try {this.shepherdService.complete()} catch (e) {}

		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
