import { Injectable } from "@angular/core";
import {catchError, combineLatest, EMPTY, forkJoin, from, mergeMap, Observable, of, throwError, toArray} from "rxjs";
import {LineBasicMaterial, LineSegments} from "three";
import { map } from "rxjs/operators";

import { MaterialService } from "../services/material.service";
import { ObjService } from "../services/obj.service";
import {
	Article, ArticleZone,
	ConLineSegments,
	ConMesh, Connection,
	Face,
	HardwareInterface, IItem,
	IMeshMaterial,
	Item,
	Panel,
	Polyhedron, RenderedEntity
} from "../types";
import { RenderedObjectTypeEnum } from "../enums";
import { createLines } from "../scenes/helpers";



@Injectable({
	providedIn: 'root',
})
export class RenderedEntityBuilder {
	private objService: ObjService
	private materialService: MaterialService

	constructor(objService: ObjService, materialService: MaterialService ) {
		this.objService = objService;
		this.materialService = materialService;
	}

	public createFace(data: Partial<Face>, iMeshMaterial?: IMeshMaterial) : Observable<Face> {
		if (!data) return of(null)
		const face = new Face(data);
		face.defaultMaterial = this.materialService.getMaterial(face?.mesh || iMeshMaterial)
		face.renderedObjects = [new ConMesh(face.bufferGeometry,
			face.defaultMaterial,
			RenderedObjectTypeEnum.ENTITY,
			face as unknown as RenderedEntity)];
		// console.log('FACE', face)
		return of(face)
	}

	public createPolyhedron(data: Partial<Polyhedron>): Observable<Polyhedron> {
		if (!data ) return of(null);
		const facesWithMesh = data?.faces.filter((face: any) => !!face.mesh);
		if (data.mesh && facesWithMesh.length > 0) {
			return of(null);
			// return throwError(() => new Error('Polyhedron and faces have mesh info set'));
		}
		if (!data.mesh && facesWithMesh.length !== data.faces.length) {
			return of(null);
			// return throwError(() => new Error('No mesh information for every face'));
		}
		if (!data?.faces || !data?.faces?.length) {
			return of(null);
		}
		return from(data.faces).pipe(
			mergeMap(faceData => this.createFace(faceData, data.mesh)),
			toArray(),
			map(faces => {
				return  new Polyhedron({ ...data, faces: faces });
			})
		);
	}

	public createPanel(data: Partial<Panel>): Observable<Panel> {
		if (!data) return of(null)

		const polyhedron$ = this.createPolyhedron(data.polyhedron);

		if (!data.handleConnection?.hardware) {
			return polyhedron$.pipe(
				map(polyhedron => {
					const panel = new Panel({ ...data, polyhedron });
					this.addLinesToPanel(panel);
					return panel;
				})
			);
		}

		return forkJoin([polyhedron$, this.createConnection(data.handleConnection)]).pipe(
			map(([polyhedron, hw]) => {
				const panel = new Panel({ ...data, polyhedron, handleConnection: hw });
				this.addLinesToPanel(panel);
				return panel;
			})
		);
	}

	private addLinesToPanel(panel: Panel) {
		if (!panel) return of(null)
		const material = this.materialService.getMaterial(
			{colour: "#000000", opacity: 1} as IMeshMaterial,
			false,
			true
		);
		if (!(material instanceof LineBasicMaterial)) {
			return throwError(() => new Error("Expected LineBasicMaterial but got a different type"));
		}
		if (!panel.polyhedron.bufferGeometry) {
			return null;
		}
		let lines = createLines(
			panel.polyhedron.bufferGeometry,
			material,
			true);
		const conLines: ConLineSegments[] = [];
		lines.traverse((line) => {
			if (line instanceof LineSegments) {
				conLines.push(
					new ConLineSegments(line.geometry, line.material, RenderedObjectTypeEnum.ENTITY, panel as unknown as RenderedEntity)
				);
			}
		});
		panel.renderedObjects.push(...conLines)
		panel.defaultMaterial = material;
	}

	public createHardwareInterface(data: any): Observable<HardwareInterface> {
		if (!data) return of(null)
		const hardware = new HardwareInterface(data)
		return this.objService.fromHardware(hardware)
	}


	public createConnection(data: Partial<Connection>): Observable<Connection> {
		if (!data) return of(null)
		if (!data.hardware || data.hardware.length === 0) {return of(new Connection(data))}

		const hwObservables = data.hardware.map(hw => this.createHardwareInterface(hw))
		return forkJoin(hwObservables).pipe(
			map((hardware) => {
				return new Connection({...data, hardware})
			})
		);
	}

	public createArticle(data: Partial<Article>): Observable<Article> {
		if (!data) return of(null);
		return forkJoin([
			this.createPanels(data.panels),
			this.createHardwareInterfaces(data.hardware),
			this.createArticle(data.base),
			this.createPolyhedra(data.polyhedra)
		]).pipe(map(([panels,  hardware, base, polyhedra]) => {
			return new Article({ ...data, panels, hardware, base,  polyhedra})
		}))
	}

	public createArticleZone(data: Partial<ArticleZone>): Observable<ArticleZone> {
		if (!data) return of(null);
		return forkJoin([
			this.createArticle(data.article),
			this.createPolyhedron(data.polyhedron)]).pipe(
			map(([article, polyhedron]) =>
				new ArticleZone({ ...data, article, polyhedron: polyhedron ? polyhedron : null}))
		);
	}

	public createPolyhedra(data: Partial<Polyhedron[]>): Observable<Polyhedron[]> {
		if (!data) return of([]);
		if (!data.length) {
			return of([]);
		}
		if (!Array.isArray(data)) {
			return of([]);
			// return throwError(() => new Error("Create polyhedra needs to be called with array like data"))
		}

		return from(data).pipe(
			mergeMap(polyData=> {
			const polyhedron = this.createPolyhedron(polyData);
			return polyhedron}), toArray())
	}

	public createItem(data: Partial<IItem>): Observable<Item> {
		if (!data) return of(null);
		if (Object.keys(data).length === 0 && data.constructor === Object) {
			return  of(null);
		}
		const res =  forkJoin([
			this.createArticles(data.articles),
			this.createArticles(data.fillerArticles),
			this.createArticleZones(data.articleZones),
			this.createArticleZones(data.fillerZones),
		]).pipe(map(([articles, fillerArticles,articleZones, fillerZones]) => {
			const item = new Item({ ...data, articles, fillerArticles, articleZones, fillerZones });
			// console.log('item_forkJoin', item)

			return item ? item : null;
		}));
		return res;
	}

	public createPanels(data: any): Observable<Panel[]> {
		if (!data || !data.length) return of([])
		if (!Array.isArray(data)) {
			return throwError(() => new Error("Create panels needs to be called with array like data"));
		}
		return from(data).pipe(mergeMap(panelData => {
			return this.createPanel(panelData);
		}
		), toArray())
	}

	public createArticles(data: any): Observable<Article[]> {
		if (!data) return of([]);
		if (!data?.length) {
			return of([]);
		}
		if (!Array.isArray(data)) {
			return throwError(() => new Error("Create articles needs to be called with array like data"));
		}
		return from(data).pipe(
			mergeMap(articleData => {
				return this.createArticle(articleData);}
			), toArray())
	}

	public createHardwareInterfaces(data: any): Observable<HardwareInterface[]> {
		if (!data) return of([])
		if (!data.length) {
			return of([]);
		}
		if (! Array.isArray(data)) {
			return throwError(() => new Error("Create panels needs to be called with array like data"))
		}
		return from(data).pipe(mergeMap(hwData => this.createHardwareInterface(hwData)
		), toArray())
	}

	public createArticleZones(data: any): Observable<ArticleZone[]> {
		if (!data) return of([])
		if (!data.length) {
			return of([]);
		}
		if (! Array.isArray(data)) {
			return throwError(() => new Error("Create articlezones needs to be called with array like data"))
		}
		return from(data).pipe(mergeMap(azData => this.createArticleZone(azData)), toArray())
	}

}
