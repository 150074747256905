import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Customisation } from '~shared/types';
import { FrontConnection } from '~shared/enums';

@Injectable()
export class CustomisationService {
	public baseUrl: string = environment.baseUrl;

	constructor(private http: HttpClient) {}

	public createCustomisation(item: Omit<Customisation, 'id'>): Observable<Customisation> {
		return this.http
			.post<Customisation>(`${this.baseUrl}part//item`, item);
	}

	public getCustomisations(customisation_id: string[]): Observable<Customisation[]> {
		return this.http
			.get<Customisation[]>(`${this.baseUrl}/customisation-api/v1/customisation`, {
				params: { customisation_id }
			});
	}

	public updateCustomisation(customisationId: string, customisation): Observable<Customisation> {
		return this.http
			.put<Customisation>(`${this.baseUrl}/customisation-api/v1/customisation/${customisationId}`, customisation);
	}

	public createPanelCustomisation(partId: string, itemId: string, panelId: string, customisation): Observable<Customisation> {
		return this.http
			.post<Customisation>(`${this.baseUrl}/customisation-api/v1/part/${partId}/item/${itemId}/panel/${panelId}/customisation`, customisation);
	}

	public createLayoutCustomisation(partId: string, itemId: string, articleZoneId: string, customisation): Observable<Customisation> {
		return this.http
			.post<Customisation>(`${this.baseUrl}/customisation-api/v1/part/${partId}/item/${itemId}/articlezone/${articleZoneId}/customisation`, customisation);
	}

	public createPartCustomisation(partId: string, customisation): Observable<Customisation> {
		return this.http
			.post<Customisation>(`${this.baseUrl}/customisation-api/v1/part/${partId}/customisation`, customisation);
	}

	public createItemCustomisation(partId: string, itemId: string, customisation): Observable<Customisation> {
		return this.http
			.post<Customisation>(`${this.baseUrl}/customisation-api/v1/part/${partId}/item/${itemId}/customisation`, customisation);
	}

	public createFile(customisationId: string, formData: FormData): Observable<Customisation> {
		return this.http
			.post<Customisation>(`${this.baseUrl}/customisation-api/v1/customisation/${customisationId}/file`, formData);
	}

	public deleteFile(customisationId: string, fileKey: string): Observable<void> {
		return this.http
			.delete<void>(`${this.baseUrl}/customisation-api/v1/customisation/${customisationId}/file/${fileKey}`);
	}

	public deleteCustomisation(customisationId: string): Observable<void> {
		return this.http
			.delete<void>(`${this.baseUrl}/customisation-api/v1/customisation/${customisationId}`);
	}

	public downloadFile(customisationId: string, fileKey: string): Observable<void | string> {
		return this.http
			.get(`${this.baseUrl}/customisation-api/v1/customisation/${customisationId}/file/${fileKey}`, {
				responseType: 'text'
			});
	}
}
