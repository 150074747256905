import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ProjectsComponent} from "~modules/project/pages/projects/projects.component";
import {CreateComponent} from "~modules/project/pages/create/create.component";
import {DetailComponent} from "~modules/project/pages/detail/detail.component";


const routes: Routes = [
	{
		path: '',
		component: ProjectsComponent
	},
	{
		path: 'create',
		component: CreateComponent
	},
	{
		path: 'details/:projectId',
		component: DetailComponent
	},
	{
		path: ':projectId',
		loadChildren: () => import('~modules/configurator/configurator.module').then(m => m.ConfiguratorModule)
	}

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ProjectRoutingModule {}
