export const ProjectStatuses = {
	PROSPECT: {
		label: 'Prospect',
		color: '#FFF'
	},
	IN_PROGRESS: {
		label: 'In uitvoering',
		color: '#FFF'
	},
	DESIGN_IN_PROGRESS: {
		label: 'In ontwerp',
		color: '#FFF'
	},
	READY_FOR_PRODUCTION: {
		label: 'Klaar voor productie',
		color: '#FFF'
	},
}
export const PartStatuses = {
  IN_ITERATION: {
    label: "Configureren",
    color: "#D1C6B4"
  },
  READY_FOR_DESIGN: {
    label: "Klaar voor ontwerp",
    color: "#D1BD9B"
  },
  DESIGN_IN_PROGRESS: {
    label: "Ontwerp",
    color: "#B7A078"
  },
  READY_FOR_PRODUCTION: {
    label: "Klaar voor productie",
    color: "#8E7A58"
  },
  IN_PRODUCTION: {
    label: "Productie",
    color: "#DBC677"
  },
  PRODUCED: {
    label: "Geproduceerd",
    color: "#D4B643"
  },
  READY_FOR_DELIVERY: {
    label: "Klaar voor levering",
    color: "#95AE8D"
  },
  DELIVERED: {
    label: "Geleverd",
    color: "#739867"
  },
  AFTER_CARE: {
    label: "Naservice",
    color: "#54B1B8"
  }
};
