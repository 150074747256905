import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { devTools } from '@ngneat/elf-devtools';

import { AddHeaderInterceptor } from '~core/interceptors/header.interceptor';
import { AuthService } from '~core/services/auth.service';
import {ProjectModule} from "~modules/project/project.module";

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ErrorHandlerInterceptor } from './core/interceptors/error-handler.interceptor';
import { ConfigService } from './shared/services/config.service';
import { WrapperComponent } from './core/pages/wrapper/wrapper.component';

devTools();

// Amplify.configure({
//   userPoolId: 'eu-central-1_QS2zMyVKB',
//   userPoolWebClientId: '6gu7b2b6plqlpnb67aftsvvu61'
// });

export function initialConfigLoad(configService: ConfigService): () => void {
	return () => configService.load();
}

@NgModule({
	declarations: [AppComponent, WrapperComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		CommonModule,
		SharedModule,
		ToastrModule.forRoot({
			progressBar: true,
			timeOut: 10000,
			positionClass: 'toast-bottom-right',
			closeButton: true,
		}),
		// AmplifyUIAngularModule,
		// AuthModule,
		CoreModule,
		ProjectModule,
		HttpClientModule
	],
	bootstrap: [AppComponent],
	providers: [
		// ...GetEnvironmentSpecificProviders(),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorHandlerInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AddHeaderInterceptor,
			multi: true,
		},
		ConfigService,
		AuthService,
		{
			provide: APP_INITIALIZER,
			useFactory: initialConfigLoad,
			deps: [ConfigService],
			multi: true,
		},
	],
})
export class AppModule {}
