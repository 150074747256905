import { Injectable } from "@angular/core";
import { setProps } from "@ngneat/elf";
import {
	BehaviorSubject,
	combineLatest,
	combineLatestWith,
	debounceTime,
	distinctUntilChanged, EMPTY,
	filter,
	Observable,
	of, shareReplay, startWith,
	Subject,
	switchMap, tap
} from "rxjs";
import { map } from "rxjs/operators";

import {
	ConfiguratorMode,
	PanelType,
	PlaneAxis,
} from "~shared/enums";
import {
	Article,
	ArticleZone,
	equalsRenderedEntities,
	equalsRenderedEntity,
	Face,
	IEdge,
	Item,
	Panel,
	RenderedEntity
} from "~shared/types";
import { AppRepository } from "~shared/store";
import { SelectionMode } from "~shared/scenes/scene-enums";

import { ProjectsRepository } from "../../../project/store/projects/projects.repository";

import { configuratorStore } from "./configurator.store";
import { configuratorSelector } from "./configurator.selectors";
import {RenderedEntityBuilder} from "~shared/builder/rendered.entity.builder";
import {ItemsRepository} from "~modules/project/store/items/items.repository";


@Injectable()
export class ConfiguratorRepository {
	constructor(private readonly appRepository: AppRepository,
	            private readonly renderEntityBuilder: RenderedEntityBuilder,
				private readonly projectsRepository: ProjectsRepository) {}
	// Visibility
	public panelsTransparent$ = configuratorSelector.panelsTransparent;
	public frontsVisible$ = configuratorSelector.frontsVisible$;
	public fullscreen$ = configuratorSelector.fullScreen$;

	// Behaviour
	public configuratorMode$ = configuratorSelector.configuratorMode$;
	public isolatedPanel$ = configuratorSelector.isolatedPanel;
	public disableFields$ = combineLatest([this.appRepository.loading$, this.projectsRepository.activeProject$])
		.pipe(map(([loading, project]) => loading || project?.locked));
	public updateRequired$: Subject<boolean> = new Subject();
	public triggerCabinetCreation$: Subject<boolean> = new Subject();

	// Selection
	public selectionMode$ = configuratorSelector.selectionMode$;



	// Groomed selection observables to only emit values when all conditions are met (mostly to trigger configuration components)
	public selectedItem$: Observable<Item | null> = configuratorSelector.selectedEntities$.pipe(
		map(() => this.getSelectedItem()),
		distinctUntilChanged(equalsRenderedEntity),
		switchMap((item) => {
		// const itemDeserialized = item ? (item instanceof Item ? of(item) : this.renderEntityBuilder.createItem(item)) : EMPTY;
		const itemDeserialized = item ? of(item) : EMPTY;

		 return itemDeserialized;
		}),
		shareReplay(1)
	);
	public selectedEntities$ = configuratorSelector.selectedEntities$.pipe(
		distinctUntilChanged(equalsRenderedEntities),
		shareReplay(1)
	);


	public lastSelectedItem = this.selectedItem$


	public selectedArticleZone$: Observable<ArticleZone> = configuratorSelector.selectedEntities$.pipe(
		map(() => this.getSelectedArticleZone()),
		distinctUntilChanged(equalsRenderedEntity)
	);

	public selectedArticle$: Observable<Article | null> = configuratorSelector.selectedEntities$.pipe(
		map(() => this.getSelectedArticle()),
		distinctUntilChanged(equalsRenderedEntity)
	);

	public selectedPanel$: Observable<Panel | null> = configuratorSelector.selectedEntities$.pipe(
		map(() => this.getSelectedPanel()),
		distinctUntilChanged(equalsRenderedEntity)
	);

	public selectedArticleZones$: Observable<ArticleZone[]> = configuratorSelector.selectedEntities$.pipe(
		map(() => this.getSelectedArticleZones()),
		distinctUntilChanged(equalsRenderedEntities)
	);

	public selectedEdge$: Observable<IEdge> = configuratorSelector.selectedEntities$.pipe(
		// TODO: still to be checked
		combineLatestWith(this.selectedItem$, this.isolatedPanel$, this.selectionMode$),
		map(([rObjects, item, isolatedPanel, selectionMode]) => {
			if (!(isolatedPanel instanceof Panel) || selectionMode !== SelectionMode.ARTICLE) {
				return null;
			}
			const faces = rObjects.filter((rObject) => rObject instanceof Face) as Face[];
			if (faces.length === 0) {
				return null;
			}

			return isolatedPanel.edges.find(edge => faces.some(face => Number(face.faceIdx) === edge.faceIndex)) || null;
		}),
		filter((edge): edge is IEdge => edge !== null) // Only emit if all conditions are met
	);

	// Configurator panel right hand side
	public activeConfigurationComponent$: Observable<string | null> =
		this.selectedPanel$.pipe(
			map((panel) => {
				console.log('panel_activeConfigurationComponent$', panel)
				const article = this.getSelectedArticle()
				if (!panel || !article) return null;

				switch (panel.panelType) {
				case PanelType.DIVIDER:
					switch (article.divider?.axis) {
					case PlaneAxis.X: return "XZ_DIVIDER";
					case PlaneAxis.Y: return "YZ_DIVIDER";
					default: throw new Error("Divider axis not supported");
					}
				case PanelType.FILLER_FRONT:
				case PanelType.FILLER_RECESSED:
				case PanelType.FILLER_PANEL:
					return "FILLER_CONNECTION";
				case PanelType.SHELF:
					return "SHELVES";
				default:
					return article.articleType;
				}
			})
		)


	// Getters and setters for store
	public setActiveConfigurationPanel(name: string) {
		configuratorStore.update(setProps({
			activeConfigurationPanel: name})
		);
	}

	public setPartEditorFullscreen(partEditorFullscreen: boolean): void {
		configuratorStore.update(
			setProps({
				fullscreen: partEditorFullscreen
			})
		);
	}

	public setSelectedEntities(objects: RenderedEntity[]): void {

		console.log('objects', objects)
		if (!objects || !objects?.length) {
			return;
		}
		configuratorStore.update(
			setProps({
				selectedEntities: objects,
			})
		);
	}

	public setSelectionMode(mode: SelectionMode): void {
		configuratorStore.update(
			setProps({
				// selectedEntities: [],
				selectionMode: mode
			})
		);
	}

	public setIsolatedPanel(isolatedPanel: Panel): void {
		configuratorStore.update(
			setProps({
				isolatedPanel: isolatedPanel
			})
		);
	}

	public setDesignerMode(designerMode: ConfiguratorMode): void {
		configuratorStore.update(
			setProps({
				configuratorMode: designerMode
			})
		);
	}


	public setPanelsTransparent(transparent: boolean): void {
		configuratorStore.update(
			setProps({
				panelsTransparent: transparent
			})
		);
	}


	public setFrontsVisible(visible: boolean): void {
		configuratorStore.update(
			setProps({
				frontsVisible: visible,
			})
		);
	}

	public getIsolatedPanel() {
		return configuratorStore.query(
			(state) => state.isolatedPanel
		)
	}

	public getPanelsTransparent() {
		return configuratorStore.query(
			(state) => state.panelsTransparent
		)
	}

	public getFrontsVisible() {
		return configuratorStore.query(
			(state) => state.frontsVisible
		)
	}

	public getSelectionMode() {
		return configuratorStore.query(
			(state) => state.selectionMode
		)
	}

	public getSelectedEntities() {
		return configuratorStore.query((state) => state.selectedEntities)
	}

	// Entity filters
	public getSelectedItem(): Item|null {
		const selectedEntity = this.getSelectedEntities().find(rObject => {
			console.log(rObject instanceof Item, rObject)
			return (rObject as Item).itemType;
		});



		return selectedEntity  ? selectedEntity as Item : null;
	}

	public getSelectedArticle(): Article|null {
		if (this.getSelectionMode() !== SelectionMode.ARTICLE) return null;
		const selectedArticles = this.getSelectedEntities().filter(rObject => rObject instanceof Article)
		return selectedArticles.length === 1 ? selectedArticles[0] as Article : null
	}

	public getSelectedPanel(): Panel|null {
		if (this.getSelectionMode() !== SelectionMode.ARTICLE) return null;
		const selectedPanels = this.getSelectedEntities().filter(rObject => rObject instanceof Panel)
		return selectedPanels.length === 1 ? selectedPanels[0] as Panel : null
	}

	public getSelectedArticleZone(): ArticleZone|null {
		if (this.getSelectionMode() !== SelectionMode.ARTICLE) return null;
		const azs = this.getSelectedEntities().filter(rObject => rObject instanceof ArticleZone)
		return azs.length === 1 ? azs[0] as ArticleZone : null
	}

	public getSelectedArticleZones(): ArticleZone[]|null {
		if (this.getSelectionMode() !== SelectionMode.ARTICLE_ZONES) return null;
		const azs = this.getSelectedEntities().filter(rObject => rObject instanceof ArticleZone)
		return azs.length > 0 ? azs as ArticleZone[] : null
	}

	// Utilities
	public refreshSelectedEntityInstances(updatedItem: Item): void {
		const updatedRenderedEntities = updatedItem.getAllRenderedEntities();
		const updatedEntities = this.getSelectedEntities().map(entity =>
			updatedRenderedEntities.find(updatedEntity => equalsRenderedEntity(updatedEntity, entity)) || entity
		);
		this.setSelectedEntities(updatedEntities);
	}

}
