import { ICoordinates } from "./dimension.types";
import { ISize } from "./size.types";


export interface IObjPosition {
	objLabel: string;
	rotation: ICoordinates;
	position: ICoordinates;
	offset?: ICoordinates;
	scale?: ICoordinates;
}

export interface IObject {
	insertion_point: ICoordinates;
	rotation: ICoordinates;
	size: ISize;
	offset: IOffset;
}

export enum IOffset {
   x = 'x',
   y = 'y'
}
