import { Injectable } from "@angular/core";
import { Group, Object3D } from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { Finish, HardwareColour, RenderedObjectTypeEnum } from "../enums";
import { ConGroup, HardwareInterface, RenderedEntity } from "../types";



@Injectable({
	providedIn: 'root',
})
export class ObjService {
	private objCache = new Map<string, Group>();

	private deg2rad(degrees: number): number {
		return degrees * (Math.PI / 180);
	}

	private applyObjPosition(object: Object3D, objPosition: any): void {
		object.position.set(
			Number(objPosition.position.x),
			Number(objPosition.position.y),
			Number(objPosition.position.z)
		);

		object.rotation.set(
			this.deg2rad(Number(objPosition.rotation.x)),
			this.deg2rad(Number(objPosition.rotation.y)),
			this.deg2rad(Number(objPosition.rotation.z))
		);

		if (objPosition.scale) {
			object.scale.set(
				Number(objPosition.scale.x),
				Number(objPosition.scale.y),
				Number(objPosition.scale.z)
			);
		}
	}

	private loadFile(baseUrl: string, variant: HardwareColour | Finish): Observable<Group> {
		const cacheKey = `${baseUrl}_${variant}`;

		if (this.objCache.has(cacheKey)) {
			// Clone the cached object before returning
			return of(this.objCache.get(cacheKey)!.clone() as Group);
		}

		const objLoader = new OBJLoader();
		const mtlLoader = new MTLLoader();
		const mtlUrl = `${baseUrl}_${variant}.mtl`;
		const objUrl = `${baseUrl}.obj`;

		return new Observable<Group>((observer) => {
			mtlLoader.load(
				mtlUrl,
				(materialCreator) => {
					materialCreator.preload();
					objLoader.setMaterials(materialCreator);

					objLoader.load(
						objUrl,
						(loadedGroup) => {
							this.objCache.set(cacheKey, loadedGroup); // Store original in cache
							observer.next(loadedGroup.clone() as Group) ; // Return a clone
							observer.complete();
						},
						undefined,
						(error) => observer.error(new Error(`Failed to load OBJ file: ${error}`))
					);
				},
				undefined,
				(error) => observer.error(new Error(`Failed to load MTL file: ${error}`))
			);
		});
	}

	fromHardware(hardwareInterface: HardwareInterface): Observable<HardwareInterface> {
		// Will load hardware for each objectposition
		const observables: Observable<void>[] = [];
		const hardwareGroup = new ConGroup(RenderedObjectTypeEnum.ENTITY, hardwareInterface as unknown as RenderedEntity);

		hardwareInterface.objPositions.forEach((objPosition) => {
			const objPath = `assets/media/obj/${hardwareInterface.catalogItem.hardwareType.toLowerCase()}/${objPosition.objLabel}`;

			const obj$ = this.loadFile(objPath, hardwareInterface.variant.colour).pipe(
				map((loadedGroup) => {
					this.applyObjPosition(loadedGroup, objPosition);
					loadedGroup.children.forEach((child) => hardwareGroup.add(child));
					hardwareGroup.castShadow = true;
					hardwareGroup.receiveShadow = true;
					hardwareInterface.renderedObjects.push(hardwareGroup);
				})
			);

			observables.push(obj$);
		});

		return forkJoin(observables).pipe(map(() => hardwareInterface));
	}

	clearObjCache() {
		this.objCache.clear()
	}
}
